import React, { useEffect, useState } from "react";
import { createSlug } from "../../../util/createSlug";
import parse from "html-react-parser";

export default function PostFour(props) {
    const { noticia } = props;

    const [categories, setCategories] = useState([])
    useEffect(() => {
        if (noticia) {
            setCategories(noticia.Categories.split(','))
        }
    }, [noticia])

    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.src) {
                return <React.Fragment></React.Fragment>;
            }
        }
    };

    return (
        <div className="post post-four">
            <a href={noticia.guid} target="_blank" rel="noreferrer">
                <figure className="post-media">
                    <img src={noticia.url} alt="post-media" />
                </figure>
            </a>

            <div className="post-detail">
                <div className="d-flex flex-wrap align-items-center my-3">
                    {categories ?
                        categories.map((item, key) => (
                            <div key={key} className="d-inline-block btn-wrapper btn-category my-1 mr-2">
                                <a target="_blank" rel="noreferrer" href={`https://blog.bigmidia.com/category/${createSlug(item)}`} className="btn text-uppercase">{item}</a>
                            </div>
                        )) : ""}
                </div>
                <a href={noticia.guid} target="_blank" rel="noreferrer">
                    <h2>{noticia.post_title}</h2>
                </a>
                <p className="blog-post-resumo">{(parse(noticia.post_content.replace(/&nbsp;/g, ''), options))}</p>

                <div className="btn-wrapper">
                    <a href={noticia.guid} target="_blank" rel="noreferrer" className="btn btn-link text-uppercase">Ler no blog <i className="fa fa-arrow-right-long"></i></a>
                </div>
            </div>
        </div >
    )
}