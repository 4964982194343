import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar
} from "@mui/material";
import React from "react";

function TabelaMapaPontos({ loading, equipes }) {
  return (
    <Table className="table-mapa">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Equipe</TableCell>
          <TableCell>Pontos</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(loading ? Array.from(new Array(2)) : equipes).map((item, key) => (
          <TableRow key={key}>
            <TableCell>{key + 1}º</TableCell>
            <TableCell>
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  {loading ? (
                    <Skeleton variant="rectangular" height={50} width={80} />
                  ) : (
                    <Avatar
                      variant="rounded"
                      src={`${process.env.REACT_APP_SGE_URL}/${item.equipe.urlLogo}`}
                    />
                  )}
                </div>
                {loading ? (
                  <Skeleton variant="text" height={"3rem"} width={"60%"} />
                ) : (
                  item.equipe.descricao
                )}
              </div>
            </TableCell>
            <TableCell>
              {loading ? <Skeleton variant="text" width={100} /> : parseFloat(item.pontos).toFixed(2)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TabelaMapaPontos;
