import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { ConfedApi } from "../../../../../api";

export const useEventoEquipe = (idEvento) => {
  const [eventoEquipe, setEventoEquipe] = useState({
    loading: false,
    error: true,
    payload: [],
  });

  const fetchEventoEquipe = async () => {
    await ConfedApi.get("evento-equipe", {
      params: {
        id_evento: idEvento,
        expand: "equipe,estabelecimento",
        sort: "divisao,-pontos",
        pageSize: 2000,
      },
    }).then((response) => {
      if (response.data._meta.totalCount === 0) {
        setEventoEquipe({
          loading: false,
          error: true,
          payload: [],
        });
      } else {
        //console.log(response.data.items);
        for(var i=0;i<response.data.items.length;i++)
        {
          response.data.items[i].pontos=response.data.items[i].pontos*1;
        }
        //console.log(response.data.items);
        setEventoEquipe({
          loading: false,
          error: false,
          payload: _.chain(response.data.items)
              .orderBy(["divisao","pontos"],["asc","desc"])
            .groupBy("divisao")
            .toArray()
            .value(),
        });
      }
    });
  };

  useEffect(() => {
    fetchEventoEquipe();
  }, []);

const updatePoints = () =>{
  fetchEventoEquipe();
}

  const mapaPontos = useMemo(
    () =>
      eventoEquipe.payload.map((item) => {
        let order = _.orderBy(item, ["divisao","pontos"], ["asc","desc"]);
        return {
          divisao: item[0].divisao,
          equipes: order,
        };
      }),
    [eventoEquipe]
  );

  return {
    loading: eventoEquipe.loading,
    error: eventoEquipe.error,
    mapaPontos,
    updatePoints,
  };
};
