import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { governancaApi, portalApi } from "../../../api";
import Breadcrumbs from "../../common/Breadcrumbs";

import { Alert, Button, Container, Grid, Typography } from "@mui/material";
import temporadas from "../../../assets/data/temporadas";
import PostHorizontal from "../../features/posts/post-horizontal";
import GridNoticias from "./GridNoticias";
import CardNoticia from "./CardNoticia";

export default function Noticias() {
  //notícias
  const [noticias, setNoticias] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [inputText, setInputText] = useState("");
  const [temporada, setTemporada] = useState("");
  // const [page, setPage] = useState(1);

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: [],
    });
    setMaisNoticias({
      loading: false,
      error: false,
      hasMore: true,
      payload: [],
    });
    let params = {
      flag_del: 0,
      flag_publicado: 1,
      id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
      arrayIn: "id_coluna:1|2",
      expand: "idEsporte,user,idPessoa",
      sort: "-created_at",
      lessField: "created_at",
      lessValue: new Date().getTime().toString().substr(0, 10),
      pageSize: 5,
      // page: page,
    };

    if (temporada) {
      params = {
        ...params,
        temporada: temporada,
      };
    }
    if (searchTerm) {
      params = {
        ...params,
        arrayLike: `titulo:${searchTerm},corpo:${searchTerm}`,
      };
    }

    async function getNoticias() {
      await portalApi
        .get("noticia", {
          params: params,
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticias({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setNoticias({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setNoticias({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getNoticias();
  }, [temporada, searchTerm]);

  const [maisNoticias, setMaisNoticias] = useState({
    loading: true,
    error: false,
    hasMore: true,
    payload: [],
  });

  const [pageNoticias, setPageNoticias] = useState(2);

  const fetchMoreNoticias = () => {
    setPageNoticias(pageNoticias + 1);
    if (pageNoticias > 1) {
      let params = {
        flag_del: 0,
        flag_publicado: 1,
        id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
        arrayIn: "id_coluna:1|2",
        expand: "idEsporte,user,idPessoa",
        sort: "-created_at",
        lessField: "created_at",
        lessValue: new Date().getTime().toString().substr(0, 10),
        pageSize: 5,
        page: pageNoticias,
      };
      if (temporada) {
        params = {
          ...params,
          temporada: temporada,
        };
      }
      if (searchTerm) {
        params = {
          ...params,
          arrayLike: `titulo:${searchTerm},corpo:${searchTerm}`,
        };
      }

      async function getMaisNoticias() {
        await portalApi
          .get("noticia", {
            params: params,
          })
          .then((response) => {
            let hasMore = true;
            if (
              response.data._meta.pageCount === response.data._meta.currentPage
            ) {
              hasMore = false;
            }
            setMaisNoticias({
              loading: false,
              error: false,
              hasMore,
              payload: [...maisNoticias.payload, ...response.data.items],
            });
          })
          .catch((err) => {
            setMaisNoticias({
              loading: false,
              error: true,
              hasMore: false,
              payload: [],
            });
            console.log(err);
          });
      }
      getMaisNoticias();
    }
  };

  const [documentos, setDocumentos] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setDocumentos({
      loading: true,
      error: false,
      payload: [],
    });
    async function getDocumentos() {
      await governancaApi
        .get("documento", {
          params: {
            flag_del: 0,
            expand: "arquivoDocumento",
            sort: "-created_at",
            pageSize: 6,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setDocumentos({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setDocumentos({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
    }
    getDocumentos();
  }, []);

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchTerm(e.target.value);
    }
  };

  const handleChangeTemporada = (e) => {
    if (e.target.value) {
      setTemporada(e.target.value);
    }
  };

  const breadcrumb = [{ label: "notícias", url: "/noticias" }];
  console.log(noticias);
  return (
    <>
      <Helmet>
        <title>Notícias - {process.env.REACT_APP_SITETITLE}</title>
      </Helmet>
      <Breadcrumbs paths={breadcrumb} />
      <main className="main noticias">
        <div className="ranking-list ranking-list-one">
          <div className="container">
            <div className="list-header">
              <div className="section-title mb-0">
                <h2 className="text-secondary-light">
                  Últimas
                  <br />
                  notícias
                </h2>
              </div>
              <div className="input-box ml-auto">
                <label className="text-white">Pesquisar:</label>
                <input
                  className="form-control"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                />
              </div>
              <div className="select-box ml-3">
                <label className="text-white">Temporada:</label>
                <select
                  className="form-control"
                  onChange={handleChangeTemporada}
                >
                  {temporadas.map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mb-5">
          {noticias.error ? (
            <Alert severity="error" sx={{ fontSize: 16, marginTop: "5rem" }}>
              Nenhuma notícia encontrada para os filtros selecionados.
            </Alert>
          ) : (
            <React.Fragment>
              <GridNoticias noticias={noticias} />
              <Container className="post-group grid-noticias">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="bg-white py-5" style={{ borderRadius: 8 }}>
                      <div className="doc-list">
                        <Typography
                          variant="h4"
                          sx={{
                            fontSize: "2.25rem",
                            fontWeight: 700,
                            fontFamily: '"DM Sans", sans-serif',
                            letterSpacing: "0.1em",
                            mb: 2,
                          }}
                        >
                          Documentos Recentes:
                        </Typography>
                      </div>
                      {documentos.loading || documentos.error ? (
                        ""
                      ) : (
                        <ul className="doc-list">
                          {documentos.payload.map((item, key) => {
                            if (item.arquivoDocumento.length > 0) {
                              return (
                                <li key={key} className="doc-list-item">
                                  <a
                                    target="_blank"
                                    href={`${process.env.REACT_APP_SGE_URL}_uploads/arquivoDocumento/${item.arquivoDocumento[0].nome_arquivo}`}
                                    rel="noreferrer"
                                  >
                                    {item.titulo}
                                  </a>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* <GridNoticias
                    noticias={{
                      payload: [noticias.payload[4]],
                      loading: noticias.loading,
                    }}
                  /> */}
                    <CardNoticia
                      titleClass={"post-title"}
                      noticia={noticias.payload[4]}
                      loading={noticias.loading}
                    />
                  </Grid>
                </Grid>
              </Container>
            </React.Fragment>
          )}
        </div>
        <div className="container pb-5">
          {maisNoticias.error ? (
            ""
          ) : (
            <div className="row mb-5">
              {maisNoticias.payload.map((item, key) => (
                <div className="col-md-6 mb-1">
                  <PostHorizontal
                    key={key}
                    noticia={item}
                    loading={maisNoticias.loading}
                  />
                </div>
              ))}
            </div>
          )}
          {maisNoticias.hasMore ? (
            <div className="text-center">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => fetchMoreNoticias()}
                className="btn-mais-noticias"
              >
                Mais notícias
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </main>
    </>
  );
}
