import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { governancaApi } from "../../api";
import Breadcrumbs from "../common/Breadcrumbs";
import CardOne from "../features/card/card-one";
import _ from 'lodash'
import React from "react";
import { Alert, CircularProgress } from "@mui/material";
import { convertSecondsDateTime } from "../../util/convertDate";

export default function Transparencia() {
    const breadcrumb = [
        { label: "transparência", url: "/transparencia" }
    ]

    // const { idMenu } = useParams()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const [menuFilhos, setMenuFilhos] = useState([])

    useEffect(() => {
        setLoading(true);
        setError(false)
        async function getMenuGovernanca() {
            await governancaApi.get('menu-governanca', {
                params: {
                    // id: 161,
                    flag_del: 0,
                    expand: 'filhos,orgao,filhos.orgao.orgaoAnexos'
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setError(true)
                    setLoading(false);
                }
                if (response.data.items[0].filhos) {

                    let filteredOrgao = _.filter(response.data.items[0].filhos, function (o) { return o.orgao });
                    let filtered = _.filter(filteredOrgao, function (o) { return o.orgao.orgaoAnexos });

                    setMenuFilhos(_.orderBy(filtered, ['ordem'], ['asc']))
                }
                setLoading(false);
            });
        }
        getMenuGovernanca()
    }, [])


    return (
        <>
            <Helmet>
                <title>Transparência - {process.env.REACT_APP_SITETITLE}</title>
            </Helmet>
            <Breadcrumbs paths={breadcrumb} />
            <main className="main transparencia" style={{ marginBottom: 100 }}>
                <div className="ranking-list ranking-list-one transparencia-header">
                    <div className="container">
                        <div className="list-header">
                            <div className="section-title mb-0">
                                <h2 className="text-white">RELATÓRIO ANUAL DE GESTÃO</h2>

                                <h5 className="text-white">As atividade anuais da CBBOL são apresentadas em Assembléia Geral Ordinária, no primeiro trimestre do ano subsequente. Confira nos anexos, os Relatórios Anuais de Gestão.</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className={loading || error ? "" : "accordion right-circle"}>
                        {loading || error ?
                            <div className="my-5">
                                {loading ?
                                    <div className="d-flex justify-content-center">
                                        <CircularProgress color="primary" />
                                    </div> : ""
                                }
                                {error ?
                                    <Alert severity="error">
                                        <span style={{ fontSize: '1.5rem' }}>Nenhum cadastro encontrado
                                        </span>
                                    </Alert> : ""}
                            </div>
                            :
                            <React.Fragment>
                                {menuFilhos.map((item, key) => (
                                    <CardOne title={item.titulo ? item.titulo.toUpperCase() : ""} isExpanded={Boolean(key === 0)}>
                                        <div className="custom-table">
                                            <div className="table-header">
                                                <div className="th" style={{ width: "45%" }}>
                                                    <h4>ARQUIVOS</h4>
                                                </div>

                                                <div className="th" style={{ width: "30%" }}>
                                                    <h4>DATA</h4>
                                                </div>

                                                <div className="th" style={{ width: "25%" }}>
                                                    <h4>ANEXO</h4>
                                                </div>
                                            </div>
                                            <div className="table-body">
                                                {item.orgao.orgaoAnexos.length === 0 ?
                                                    <div className="table-row" key={key}>
                                                        <div className="td file" style={{ width: '100%' }}>
                                                            <Alert severity="error">
                                                                <span style={{ fontSize: '1.5rem' }}>Nenhum anexo encontrado
                                                                </span></Alert>
                                                        </div>
                                                    </div>
                                                    :
                                                    item.orgao.orgaoAnexos.map((item, key) => (
                                                        <div className="table-row" key={key}>
                                                            <div className="td file" style={{ width: "45%" }}>
                                                                <h4>{item.titulo}</h4>
                                                            </div>

                                                            <div className="td data" style={{ width: "30%" }}>
                                                                <h4>{convertSecondsDateTime(item.created_at * 1000)}</h4>
                                                            </div>

                                                            <div className="td dl" style={{ width: "25%" }}>
                                                                <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_SGE_URL}_uploads/orgaoAnexo/${item.nome_arquivo}`} className="download">
                                                                    <span className="text-uppercase">Download</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </CardOne>
                                ))}
                            </React.Fragment>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}