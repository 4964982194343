import { useEffect, useState } from "react";
import { portalApi } from "../../../../../api";


export const useNoticiasEvento = (idEvento) => {
  const [noticias, setNoticias] = useState({ loading: true, error: false, data: [] })

  useEffect(() => {
    async function getNoticias() {
      await portalApi.get("noticia", {
        params: {
          id_evento: idEvento
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setNoticias({ loading: false, error: true, data: [] });
        } else {
          setNoticias({ loading: false, error: false, data: response.data.items });
        }
      });
    }
    getNoticias()
  }, [idEvento])

  return { ...noticias };
};
