import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import { governancaApi } from "../../../api";
import { Accordion, AccordionDetails, AccordionSummary, Grow, Typography } from "@mui/material";
import { convertSecondsDateTime } from "../../../util/convertDate";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../common/Breadcrumbs";

export default function OrgaoPage(props) {
  const { idMenu } = useParams();
  const [menu, setMenu] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [orgaos, setOrgaos] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    async function getMenuGovernanca() {
      await governancaApi
        .get("orgao", {
          params: {
            id_menu: idMenu,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            flag_del: 0,
            expand: "orgaoAnexos,menu",
            sort: "-data",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setError(true);
          } else {
            setOrgaos(response.data.items)
          }
          setLoading(false);
        });
    }
    getMenuGovernanca();
  }, [idMenu]);

  useEffect(() => {
    async function getMenu() {
      await governancaApi
        .get(`menu-governanca/${idMenu}`)
        .then((response) => {
          setMenu(response.data)
        });
    }
    getMenu();
  }, [idMenu])


  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const anexosFiltrados = useMemo(() => {
    if (orgaos.length) {
      let order = _.orderBy(orgaos, ["created_at"], ["desc"]);
      return order
    } else {
      return []
    }
  }, [orgaos])

  const breadcrumb = [{ label: menu?.titulo || process.env.REACT_APP_ESTABELECIMENTO }];

  if (error) {
    return (
      <>
        <Helmet>
          <title>
            {menu?.titulo || "Página não encontrada"} - {process.env.REACT_APP_SITETITLE}
          </title>
        </Helmet>
        <Breadcrumbs paths={[{ label: menu?.titulo || "" }]} />
        <div className="orgao-page">
          <div className="bg-primary-light">
            <div className="container">
              <div className="py-3">
                <div className="section-title my-5 pb-4">
                  <h2 className="text-primary">{menu?.titulo || "Página não encontrada"}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="site-content" style={{ minHeight: "30vh" }}>
            <div className="container">

            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {loading ? null
        : (
          <Helmet>
            <title>
              {menu?.titulo} - {process.env.REACT_APP_SITETITLE}
            </title>
          </Helmet>
        )}

      <Breadcrumbs paths={breadcrumb} />
      <div className="orgao-page">
        <div className="bg-primary-light">
          <div className="container">
            <div className="py-3">
              <div className="section-title my-5 pb-4">
                {loading ? null
                  : (
                    <h2 className="text-primary">{menu?.titulo}</h2>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="site-content" style={{ minHeight: "30vh" }}>
          <div className="container">
            {!loading && orgaos ? (
              <div className="">
                {orgaos.map((item, key) => {
                  if (item.corpo) {
                    return (
                      <div key={key} className="orgao-content py-5">
                        {ReactHtmlParser(item.corpo)}
                      </div>
                    )
                  }
                  return (
                    null
                  )
                })}

                <div className="accordion mt-4">
                  {anexosFiltrados ? anexosFiltrados.map((item, key) => (
                    <Accordion
                      key={key}
                      elevation={0}
                      className="card p-0"
                      square
                      expanded={expanded === `panel${key}`}
                      onChange={handleChange(`panel${key}`)}
                    >
                      <AccordionSummary
                        style={{
                          backgroundColor: `${expanded === `panel${key}` ? "#256ac3" : "#b5cbe8"}`,
                          color: `${expanded === `panel${key}` ? "#fff" : "#253b58"}`
                        }}
                        aria-controls={`panel${key}d-content`}
                        id={`panel${key}d-header`}
                      >
                        <Typography variant="h5" className="uppercase fs-4 fw-bold">
                          {item.titulo}
                          <span className="ml-4 fs-5">
                            <i className={`fa fa-chevron-${expanded === `panel${key}` ? "up" : "down"}`}></i>
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="border-top">
                        <div className="table-responsive fs-4">
                          <table className="table align-middle">
                            <thead>
                              <tr>
                                <th>ARQUIVOS</th>
                                <th>DATA</th>
                                <th className="text-center">ANEXO</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.orgaoAnexos ? _.orderBy(item.orgaoAnexos, ['created_at'], ['desc']).map((item, key) => (
                                <Grow in={true} key={key} timeout={600}>
                                  <tr>
                                    <td component="th">
                                      <span className="uppercase font-lg">{item.titulo}</span>
                                    </td>
                                    <td>{convertSecondsDateTime(item.created_at * 1000)}</td>
                                    <td align="center">
                                      <a
                                        className="btn btn-primary uppercase fs-5"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${process.env.REACT_APP_SGE_URL}_uploads/orgaoAnexo/${item.nome_arquivo}`}
                                      >
                                        download
                                      </a>
                                    </td>
                                  </tr>
                                </Grow>
                              )) : null}
                            </tbody>
                          </table>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )) : null}
                </div>
              </div>
            ) :
              null}
          </div>
        </div>
      </div >
    </>
  );
}
