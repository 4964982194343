import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ConfedApi, portalApi } from "../../api";
import FeatureBox from "../partials/feature-box";
import Gallery from "../partials/gallery";
import RankingListOne from "../partials/ranking-list/ranking-list-one";
import ScheduleOne from "../partials/schedule/schedule-one";
import GridNoticias from "./noticias/GridNoticias";

export default function HomePage() {
  //notícias
  const [noticias, setNoticias] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: [],
    });
    async function getNoticias() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            flag_publicado: 1,
            id_estabelecimento: 1,
            expand: "idEsporte",
            notNullField: "foto_capa",
            sort: "-created_at",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 5,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticias({
              loading: false,
              error: true,
              payload: {
                payload: [],
              },
            });
          } else {
            setNoticias({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setNoticias({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getNoticias();
  }, []);

  //evento
  const [eventos, setEventos] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setEventos({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventos() {
      await ConfedApi.get("evento-regra", {
        params: {
          "evento.flag_del": 0,
          id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
          expand: "evento,estabelecimento",
          forcejoin: "evento",
          sort: "-evento.data_inicio",
          betweenField: "evento.data_fim",
          betweenValue1: moment().format("YYYY-MM-DD"),
          betweenValue2: moment().add(2, "M").format("YYYY-MM-DD"),
          pageSize: 5,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventos({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEventos({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setEventos({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventos();
  }, []);

  const [galeria, setGaleria] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setGaleria({
      loading: true,
      error: false,
      payload: [],
    });
    async function getGaleria() {
      await portalApi
        .get("galeria-fotos", {
          params: {
            sort: "-created_at",
            pageSize: 12,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setGaleria({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setGaleria({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
    }
    getGaleria();
  }, []);

  const [ranking, setRanking] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [rankingData, setRankingData] = useState("");
  const [idCategoria, setIdCategoria] = useState(1);
  useEffect(() => {
    async function getLastRanking() {
      await ConfedApi.get("ranking-historico", {
        params: {
          sort: "-data",
          pageSize: 1,
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setRankingData("");
        } else {
          setRankingData(response.data.items[0].data);
        }
      });
    }
    getLastRanking();
  }, []);

  useEffect(() => {
    if (rankingData) {
      setRanking({
        loading: true,
        error: false,
        payload: [],
      });
      async function getRankingHistorico() {
        await ConfedApi.get("ranking-historico/colocacao", {
          params: {
            data: moment(rankingData).format("YYYY-MM").concat("-01"),
            "rankingCategorias.id": idCategoria,
            pageSize: 10,
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setRanking({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setRanking({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
      }

      getRankingHistorico();
    }
  }, [rankingData, idCategoria]);

  const [rankingCategorias, setRankingCategorias] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setRankingCategorias({
      loading: true,
      error: false,
      payload: [],
    });
    async function getRankingCategorias() {
      await ConfedApi.get("ranking-categorias", {
        params: {
          pageSize: 40,
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setRankingCategorias({
            loading: false,
            error: true,
            payload: [],
          });
        } else {
          setRankingCategorias({
            loading: false,
            error: false,
            payload: response.data.items,
          });
        }
      });
    }
    getRankingCategorias();
  }, []);

  const handleChangeCategoria = (idCategoria) => {
    setIdCategoria(idCategoria);
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SITETITLE}</title>
      </Helmet>
      <main className="main home mt-5">
        <GridNoticias noticias={noticias} />
        <ScheduleOne eventos={eventos} />
        <div className="container-fluid position-relative ranking-section">
          <RankingListOne
            ranking={ranking}
            rankingData={rankingData}
            handleChangeCategoria={handleChangeCategoria}
            rankingCategorias={rankingCategorias}
            idCategoria={idCategoria}
          />
        </div>
        <Gallery galeria={galeria} />
        <FeatureBox />
      </main>
    </>
  );
}
