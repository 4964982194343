import { Link } from "react-router-dom";
import { convertDate } from "../../../util/convertDate";

import PlayerOne from "../../features/players/player-one";
import PlayerTwo from "../../features/players/player-two";

export default function RankingListOne(props) {
  const {
    ranking,
    rankingData,
    handleChangeCategoria,
    rankingCategorias,
    idCategoria,
  } = props;

  return (
    <div className="ranking-list ranking-list-one">
      <div className="row">
        <div className="col-lg-12  col-xl-6 first-col">
          <div className="container">
            <div className="d-md-flex">
              <div className="list-header">
                <div className="section-title mb-0">
                  <h2 className="text-secondary-dark">
                    RANKING
                    <br />
                    BRASILEIRO
                  </h2>
                </div>
              </div>
              <div className="d-xl-none ml-auto w-100">
                <div className="header-select me-md-5">
                  <div className="select-box w-100">
                    <label className="text-white">Escolha a categoria:</label>

                    <select
                      name="category"
                      className="form-control"
                      value={idCategoria}
                      onChange={(e) => handleChangeCategoria(e.target.value)}
                    >
                      {rankingCategorias.loading
                        ? ""
                        : rankingCategorias.payload.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.nome}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top-3">
            <ul className="player-list">
              {ranking.error
                ? ""
                : (ranking.loading
                    ? Array.from(new Array(3))
                    : ranking.payload.slice(0, 3)
                  ).map((item, key) => (
                    <PlayerOne
                      key={key}
                      loading={ranking.loading}
                      atleta={item}
                      number={key + 1}
                    />
                  ))}
            </ul>
          </div>
          <div className="container d-none d-xl-block">
            <div className="list-footer text-white d-flex">
              <h5>atualizado em {convertDate(rankingData)}</h5>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-6 second-col">
          <div className="container">
            <div className="d-none d-xl-block">
              <div className="list-header me-md-5">
                <div className="select-box w-100">
                  <label className="text-white">Escolha a categoria:</label>

                  <select
                    name="category"
                    className="form-control"
                    value={idCategoria}
                    onChange={(e) => handleChangeCategoria(e.target.value)}
                  >
                    {rankingCategorias.loading
                      ? ""
                      : rankingCategorias.payload.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.nome}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="top-10">
              <ul className="player-list">
                {ranking.error
                  ? ""
                  : (ranking.loading
                      ? Array.from(new Array(7))
                      : ranking.payload.slice(3, 10)
                    ).map((item, key) => (
                      <PlayerTwo
                        key={key}
                        loading={ranking.loading}
                        number={key + 4}
                        atleta={item}
                      />
                    ))}
              </ul>
            </div>
            <div className="list-footer text-white d-flex align-items-center">
              <div className="container d-xl-none">
                <div className="list-footer text-white d-flex">
                  <h5>atualizado em {convertDate(rankingData)}</h5>
                </div>
              </div>
              <div className="container d-flex">
                <Link to="/ranking" className="d-flex ml-auto text-white">
                  <h3>
                    Ver lista completa.
                    <i className="fa fa-arrow-right-long ml-4"></i>
                  </h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
