import { useEventoEquipe } from "./hooks/useEventoEquipe";
import TabelaMapaPontos from "./TabelaMapaPontos";
import { useEffect } from "react";

export default function MapaPontos({ idEvento }) {
  const { loading, error, mapaPontos, updatePoints } = useEventoEquipe(idEvento);

  useEffect(()=>{
    const updateInterval = setInterval(() => {
      updatePoints()
    }, 180000);
    
    return()=>{
      clearInterval(updateInterval)
    }
  })
 
function forceUpdate(){
  updatePoints()
}

  if (error) {
    return null;
  } 
  
  return (
    <div className="board bg-primary card-mapa">
      <h2 className="board-title">Mapa de Pontos</h2>
      <h5>Os pontos são calculados em tempo real.</h5>
      <button
        className="button-pontos"
        type="button"
        onClick={()=>forceUpdate()}
      >
        ATUALIZAR PONTOS
      </button>  
      {(loading ? Array.from(new Array(2)) : mapaPontos).map((item, key) => (
        <div key={key} className="my-5">
          <h4 className="title-mapa">
            {loading ? key + 1 : item.divisao}ª Divisão
          </h4>
          <TabelaMapaPontos loading={loading} equipes={item.equipes} />
        </div>
      ))}
    </div>
  );
}
