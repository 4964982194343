import _ from "lodash";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../../util/createSlug";

// faz empates pra teste
export function criaEmpates(tabela) {
  const testEmpate = tabela.map((item, key) => {
    if (key === 1) {
      return {
        ...item,
        media: tabela[0].media,
      };
    }
    if (key === 2) {
      return {
        ...item,
        media: tabela[0].media,
      };
    }
    return { ...item };
  });
  return testEmpate;
}

export function calculaEmpates(tabela) {
  if (tabela.length > 0) {
    const tabelaData = tabela;
    // ultiliza map para evitar repetição de dado
    const empates = new Map();
    const tabelaMap = new Map();

    tabelaData.forEach((element, index) => {
      if (index > 0) {
        // alimenta o map de empates
        if (element.media === tabelaData[index - 1].media) {
          empates.set(element.id, element);
          empates.set(tabelaData[index - 1].id, tabelaData[index - 1]);
        }
      }
      // alimenta o map da tabela completa
      tabelaMap.set(element.id, element);
    });

    // tranforma o map em array
    const values = Array.from(empates.values());

    // trata os empates caso haja
    if (values.length > 0) {
      const groupValues = _.groupBy(values, "media");

      const Empates = Object.entries(groupValues).map(([key, value]) => {
        // pega a colocação
        const sortColocacao = _.orderBy(value, ["colocacao"], ["asc"]);
        const colocacaos = sortColocacao.map((item) => Number(item.colocacao));
        const colocacaoEmpate = colocacaos[0];

        // separa os pontos pra tirar média
        const pontos = value.map((item) => Number(item.pontos));
        const totalPtsEmpatados = pontos.reduce(
          (prevValue, currentValue) => prevValue + currentValue,
          0
        );
        const mediaEmpate =
          totalPtsEmpatados > 0
            ? (totalPtsEmpatados / pontos.length).toFixed(1)
            : "";

        const newEmpatados = value.map((item) => ({
          ...item,
          colocacao: colocacaoEmpate,
          pontos: mediaEmpate,
        }));

        return { media: key, empatados: newEmpatados };
      });

      // atualiza valores da tabela
      Empates.forEach((element) => {
        element.empatados.forEach((item) => {
          tabelaMap.set(item.id, item);
        });
      });
    }

    const TabelaMapArray = Array.from(tabelaMap.values());

    // organiza colocações

    // let newTabela = [];
    // let colocacao = 1;
    // let colocacaoReal = 1;
    // TabelaMapArray.forEach((element, key) => {
    //   if (key > 0) {
    //     colocacao = ++colocacao;

    //     if (element.media === TabelaMapArray[key - 1].media) {
    //       newTabela.push({ ...element, colocacao: colocacao - 1 });
    //     } else {
    //       newTabela.push(element);
    //     }
    //   } else {
    //     newTabela.push(element);
    //   }
    // });

    return TabelaMapArray;
  } else {
    return [];
  }
}

export const handleFlags = (atleta) => {
  let uf = atleta.uf ? atleta.uf.split(",")[0] : "PR";
  return uf;
};

export const calculaTotalPts = (atleta) => {
  let anterior = atleta.anterior ? Number(atleta.anterior) : 0;
  let t = Number(atleta.pontos_serie) + anterior;
  return t;
};

export const calculaDifPrimeiro = (atleta, primeiro) => {
  let totalPts = calculaTotalPts(atleta);
  let primeiroPts = 0;
  if (primeiro) {
    primeiroPts = calculaTotalPts(primeiro);
  }

  let dif = totalPts - primeiroPts;
  return dif;
};

export const calculaDifColocacao = (atleta, tabela, categoria) => {
  let totalPts = calculaTotalPts(atleta);
  let posPts = 0;

  let pos_dif = 0;

  if (categoria && categoria.pos_dif) {
    pos_dif = Number(categoria.pos_dif - 1);
  }

  if (tabela && tabela[pos_dif]) {
    //pega a linha da tabela com na posição da pos_dif pra calcular a diferença com a linha atual
    posPts = calculaTotalPts(tabela[pos_dif]);
  }

  return totalPts - posPts;
};

export const nomeAtletas = (atleta, categoria) => {
  let nomeAtleta = atleta?.nome_completo || "";

  if (atleta.nome_evento) {
    nomeAtleta = atleta.nome_evento;
  }

  if (categoria && categoria.baker === 1) {
    return nomeAtleta;
  }
  if (atleta && atleta.id && nomeAtleta) {
    let _nomes = nomeAtleta.split(",");
    let _ids = atleta.id.toString().split(",");
    if (_nomes.length > 1) {
      return (
        <div>
          <div className="text-accent">
            <b>{atleta.equipe}</b>
          </div>
          {_nomes.map((item, key) => (
            <span key={key}>
              <Link
                className="link-td-atleta"
                to={`/atleta/${_ids[key]}/${createSlug(item)}`}
              >
                {item}{" "}
              </Link>
              {_nomes.length > key + 1 ? "/ " : ""}
            </span>
          ))}
        </div>
      );
    }
    return (
      <div>
        <Link
          className="link-td-atleta"
          to={`/atleta/${atleta.id}/${createSlug(nomeAtleta)}`}
        >
          {nomeAtleta}
        </Link>
        <div>
          <em>{atleta.equipe}</em>
        </div>
      </div>
    );
  }
  return <div />;
};
