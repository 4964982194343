import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { governancaApi } from '../../../api';
import Breadcrumbs from '../../common/Breadcrumbs';
import _ from 'lodash'

export default function Documentos() {
	const [documentos, setDocumentos] = useState({
		loading: true,
		error: false,
		payload: []
	})

	const [idTipoDocumento, setIdTipoDocumento] = useState([1, 2, 3]);

	const tipoDocumentos = [
		{ id: 1, descricao: 'Atas' },
		{ id: 2, descricao: 'Atas de Eleições' },
		{ id: 3, descricao: 'Comunicados' },
		{ id: 4, descricao: 'Editais de Convocação' },
		{ id: 5, descricao: 'Estatuto' },
		{ id: 6, descricao: 'Prestação de Contas' },
		{ id: 7, descricao: 'Regimento Interno' },
		{ id: 8, descricao: 'Regras do Esporte' },
	]

	useEffect(() => {
		setDocumentos({
			loading: true,
			error: false,
			payload: []
		})
		let tipos = ['', 'Atas', 'Atas de Eleições', 'Comunicados', 'Editais de Convocação', 'Estatuto', 'Prestação de Contas', 'Regimento Interno', 'Regras do Esporte']

		async function getDocumentos() {
			await governancaApi.get('documento', {
				params: {
					flag_del: 0,
					// id_tipo: idTipoDocumento,
					arrayIn: `id_tipo:${idTipoDocumento.join('|')}`,
					expand: 'arquivoDocumento',
					sort: '-created_at',
					pageSize: 200,
				},
			}).then((response) => {
				if (response.data._meta.totalCount === 0) {
					setDocumentos({
						loading: false,
						error: true,
						payload: []
					})
				} else {
					let group = _.chain(response.data.items).groupBy("id_tipo").map((value, key) => ({ titulo: tipos[key], data: value })).value()
					setDocumentos({
						loading: false,
						error: false,
						payload: group
					});
				}
			});
		}
		getDocumentos();

	}, [idTipoDocumento])

	// const handleChange = (event, value) => {
	// 	setPage(value);
	// 	if (value === 1) {
	// 		executeScroll()
	// 	}
	// };

	// useEffect(() => {
	// 	if (page > 1) {
	// 		executeScroll()
	// 	}
	// }, [page])

	// const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: sectionTop.current.offsetTop - 150 });

	const handleTipoDocumentos = (idTipo) => {
		if (idTipoDocumento.indexOf(idTipo) === -1) {
			setIdTipoDocumento(idTipoDocumento => [...idTipoDocumento, idTipo])
		} else {
			setIdTipoDocumento(idTipoDocumento.filter((e) => e !== idTipo))
		}
	}



	// useEffect(() => {
	// 	console.log(idTipoDocumento)
	// }, [idTipoDocumento])

	const breadcrumb = [
		{ label: "documentos", url: "/documentos" }
	]

	return (
		<>
			<Helmet>
				<title>Documentos e Legislações - {process.env.REACT_APP_SITETITLE}</title>
			</Helmet>
			<Breadcrumbs paths={breadcrumb} />
			<main className="main noticias-one">
				<div className="bg-secondary">
					<div className="container">
						<div className="section-title text-primary">
							<h2>DOCUMENTOS E LEGISLAÇÕES</h2>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="tags">
						<div className="tags-title">
							Filtro
						</div>

						<div className="tag-group">
							{tipoDocumentos.map((item, key) => (
								<Button className={`tag ${idTipoDocumento.indexOf(key + 1) > -1 ? "active" : ""}`} onClick={() => handleTipoDocumentos(item.id)} key={key}>{item.descricao}</Button>
							))}
						</div>
					</div>

					<div className="row">
						{documentos.loading || documentos.error ? "" :
							documentos.payload.map((item, key) => (
								<div key={key} className="col-md-6 doc-group">
									<div className="note-wrapper">
										<div className="note-title">
											{item.titulo}
										</div>
										<div className="notes">
											{item.data.map((item, key) => (
												<div className="note" key={key}>
													{item.arquivoDocumento[0] ?
														<a target="_blank" href={`${process.env.REACT_APP_SGE_URL}_uploads/arquivoDocumento/${item.arquivoDocumento[0].nome_arquivo}`} rel="noreferrer">
															{item.titulo}
														</a>
														:
														""
													}
												</div>
											))}
										</div>

									</div>
								</div>
							))
						}

						{/* <div className="col-md-6">
							<div className="note-wrapper">
								<div className="note-title">
									Atas de Eleições
								</div>

								<div className="notes">
									<div className="note">
										Ata de AGO de 12/12/2016 Arquivo Digitalizado - Ata de eleição - Ata de eleição registrada em cartório - Presidente, Vice-Presidente e Conselho Fiscal 2017 a 2020
									</div>
									<div className="note">
										2016 - ATA AGO de 12/11/2016 - Eleição Presidente e Conselho - Ata de Eleição do Presidente, Vice-Presidente e Conselho Fiscal para o Quadriênio 2017 a 2020
									</div>
									<div className="note">
										ATA da AGE de 01/07/2013 - Eleição do Presidente - Ata de Eleição do Presidente e Vice-Presidente para Completar o mandato de 2013 a 2016
									</div>
								</div> 
							</div>

							<div className="note-wrapper">
								<div className="note-title">
									Atas de Eleições
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</main>
		</>
	)
}