import {
  Alert,
  ButtonBase,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { portalApi } from "../../../api";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Breadcrumbs from "../../common/Breadcrumbs";

const options = {
  settings: {
    autoplaySpeed: 4000,
    boxShadow: "none",
  },
  buttons: {
    showDownloadButton: false,
  },
};

export default function GaleriaItem(params) {
  const sectionTop = useRef(null);
  const { id, slug } = useParams();
  const page = params.page;
  const [galeria, setGaleria] = useState([]);
  const [galeriaFotos, setGaleriaFotos] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    async function getGaleria() {
      await portalApi
        .get("galeria", {
          params: {
            id: id,
          },
        })
        .then((response) => {
          setGaleria(response.data.items);
          if (response.data._meta.totalCount < 1) {
            setError(true);
          }
          // console.log(response.data.items)
        });
    }
    getGaleria();
  }, [id]);

  useEffect(() => {
    if (galeria.length) {
      setLoading(true);
      setError(false);

      async function getGaleriaFotos() {
        await portalApi
          .get("galeria-fotos", {
            params: {
              id_galeria: id,
              sort: "-created_at",
              page: page,
              pageSize: 21,
            },
          })
          .then((response) => {
            setPageCount(response.data._meta.pageCount);
            setGaleriaFotos(response.data.items);
            if (response.data._meta.totalCount < 1) {
              setError(true);
            }
            setLoading(false);
            executeScroll();
          });
      }
      getGaleriaFotos();
    }
  }, [id, page, galeria]);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 210,
    });

  const breadcrumb = [
    { label: "Galerias", url: "/galerias" },
    { label: galeria[0] ? galeria[0].descricao : "", url: "" },
  ];

  return (
    <>
      <Breadcrumbs paths={breadcrumb} />
      <div ref={sectionTop}></div>
      {error ? (
        <Container>
          <Alert
            severity="error"
            sx={{ fontSize: 16, marginTop: "8rem", marginBottom: "8rem" }}
          >
            Galeria não foi encontrada.
          </Alert>
        </Container>
      ) : (
        <>
          <Container style={{ minHeight: "10vh" }}>
            <SimpleReactLightbox>
              <SRLWrapper options={options}>
                <Grid container spacing={3}>
                  {galeriaFotos.map((item, key) => (
                    <Grid item key={key} xs={12} sm={12} md={4}>
                      <div className="img-container">
                        {loading ? (
                          <Skeleton variant="rectangular" height={250} />
                        ) : (
                          <ButtonBase>
                            <img
                              src={`${process.env.REACT_APP_SGE_URL}_uploads/galeria/fotos/${item.url}`}
                              alt={item.legenda}
                            />
                          </ButtonBase>
                        )}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </SRLWrapper>
            </SimpleReactLightbox>
          </Container>
          {pageCount > 1 ? (
            <div className="pagination-wrapper mt-5">
              <div className="container-fluid">
                <Route>
                  {({ location }) => {
                    const query = new URLSearchParams(location.search);
                    const page = parseInt(query.get("p") || "1", 10);
                    return (
                      <Pagination
                        className="pagination"
                        page={page}
                        count={pageCount}
                        renderItem={(item) => (
                          <PaginationItem
                            component={Link}
                            to={`/galeria/${id}/${slug}${
                              item.page === 1 ? "" : `?p=${item.page}`
                            }`}
                            {...item}
                          />
                        )}
                      />
                    );
                  }}
                </Route>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
