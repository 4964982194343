import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumbs(props) {

    const { paths } = props

    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/" className="text-uppercase">home</Link>
                        <span>/</span>
                        {paths.map((item, key) => (
                            <React.Fragment key={key}>
                                {item.url ?
                                    <Link to={item.url} className="text-uppercase">{item.label}</Link>
                                    : <span className="text-uppercase">{item.label}</span>
                                }
                                {key + 1 < paths.length ? <span>/</span> : <></>}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
