import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import EstadoFlag from "../../../assets/img/flags/EstadosFlag";

export default function PlayerTwo(props) {
  const { atleta, loading } = props;

  return (
    <div className="player player-two text-white">
      <div className="player-info d-flex p-0 mb-0">
        <div className="text-white player-number">
          <h2>{loading ? "" : atleta.colocacao}.</h2>
        </div>

        <div className="player-name text-white">
          {loading ? (
            <Skeleton variant="text" width={120} />
          ) : (
            <Link to={`/atleta/${atleta.id}/${createSlug(atleta.nome_evento)}`}>
              {atleta.nome_evento}
            </Link>
          )}
        </div>

        <div className="player-nation">
          {loading ? (
            <Skeleton variant="rectangular" width={40} height={28} />
          ) : (
            <EstadoFlag estado={atleta ? atleta.uf : ""} />
          )}
        </div>

        <div className="total-score">
          <h3>
            {loading ? <Skeleton variant="text" width={65} /> : atleta.pontos}
          </h3>
        </div>
      </div>

      <hr className="text-white" />
    </div>
  );
}
