import { Alert, Button, IconButton, InputBase, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import brLocale from "date-fns/locale/pt-BR";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ConfedApi } from "../../api";
import Breadcrumbs from "../common/Breadcrumbs";
import PlayerThree from "../features/players/player-three";

export default function Ranking(params) {
  const queryParam = params.queryParam;
  const [ranking, setRanking] = useState({
    loading: true,
    error: false,
    hasMore: true,
    payload: [],
  });
  const [rankingTop, setRaningTop] = useState([]);
  const [idCategoria, setIdCategoria] = useState(1);
  const [selectDate, setSelectDate] = React.useState(new Date());
  const [searchTerm, setSearchTerm] = useState(queryParam);

  let history = useHistory();

  useEffect(() => {
    setRanking({
      loading: true,
      error: false,
      hasMore: false,
      payload: [],
    });

    async function getRankingHistorico() {
      await ConfedApi.get("ranking-historico/colocacao", {
        params: {
          data: moment(selectDate).format("YYYY-MM").concat("-01"),
          "rankingCategorias.id": idCategoria,
          nome_evento: queryParam,
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setRanking({
            loading: false,
            error: true,
            hasMore: false,
            payload: [],
          });
        } else {
          let hasMore = true;
          if (
            response.data._meta.pageCount === response.data._meta.currentPage
          ) {
            hasMore = false;
          }

          setRanking({
            loading: false,
            error: false,
            hasMore: hasMore,
            payload: _.filter(response.data.items, function (o) {
              return o.colocacao >= 4;
            }),
          });
          //filtra o topo do ranking
          setRaningTop(
            _.filter(response.data.items, function (o) {
              return o.colocacao <= 3;
            })
          );
        }
      });
    }

    getRankingHistorico();
  }, [idCategoria, selectDate, queryParam]);

  const [rankingCategorias, setRankingCategorias] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setRankingCategorias({
      loading: true,
      error: false,
      payload: [],
    });
    async function getRankingCategorias() {
      await ConfedApi.get("ranking-categorias", {
        params: {
          pageSize: 40,
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setRankingCategorias({
            loading: false,
            error: true,
            payload: [],
          });
        } else {          
          setRankingCategorias({
            loading: false,
            error: false,
            payload: response.data.items,
          });
        }
      });
    }
    getRankingCategorias();
  }, []);
  const handleChangeCategoria = (idCategoria) => {
    setIdCategoria(idCategoria);
    setPage(2);
  };

  const [activeCategoria, setActiveCategoria] = useState("");

  const [page, setPage] = useState(2);

  const fetchMoreData = () => {
    async function getRankingHistorico() {
      await ConfedApi.get("ranking-historico/colocacao", {
        params: {
          data: moment(selectDate).format("YYYY-MM").concat("-01"),
          "rankingCategorias.id": idCategoria,
          nome_evento: queryParam,
          pageSize: 20,
          page: page,
        },
      }).then((response) => {
        let hasMore = Boolean(
          response.data._meta.pageCount !== response.data._meta.currentPage
        );

        if (response.data._meta.totalCount === 0) {
          setRanking({
            loading: false,
            error: true,
            hasMore: hasMore,
            payload: [],
          });
        } else {
          setRanking({
            loading: false,
            error: false,
            hasMore: hasMore,
            payload: [...ranking.payload, ...response.data.items],
          });
        }
      });
    }
    getRankingHistorico();
    setPage(page + 1);
  };

  useEffect(() => {
    if (!rankingCategorias.loading || !rankingCategorias.error) {
      let categoria = _.filter(rankingCategorias.payload, function (o) {
        return Number(o.id) === Number(idCategoria);
      });
      setActiveCategoria(categoria[0] ? categoria[0].nome : "");
    }
  }, [idCategoria, rankingCategorias]);

  const breadcrumb = [{ label: "ranking", url: "/ranking" }];

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm) {
        history.push(`/ranking?q=${searchTerm}`);
      } else {
        history.push("/ranking");
      }
    }
  };
  const handleSearchClick = () => {    
      if (searchTerm) {
        history.push(`/ranking?q=${searchTerm}`);
      } else {
        history.push("/ranking");
      }    
  };

  return (
    <>
      <Helmet>
        <title>Ranking - {process.env.REACT_APP_SITETITLE}</title>
      </Helmet>
      <Breadcrumbs paths={breadcrumb} />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={brLocale}
      >
        <main className="main ranking-page">
          <div className="ranking-list ranking-list-one">
            <div className="bg-primary-light">
              <div className="container">
                <div className="list-header">
                  <div className="section-title mb-0">
                    <h2 className="text-primary">RANKING BRASILEIRO</h2>
                  </div>
                </div>

                <div className="list-body row">
                  <div className="col-lg-6">
                    <div className="select-box m-0 mb-5">
                      <label className="text-primary">
                        <b>Escolha a categoria:</b>
                      </label>

                      <select
                        onChange={(e) => handleChangeCategoria(e.target.value)}
                        name="category"
                        className="form-control"
                        value={idCategoria}
                      >
                        {rankingCategorias.loading
                          ? ""
                          : rankingCategorias.payload.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.nome}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="select-mui my-3">
                      <label className="text-primary">
                        <b> Escolha o periodo:</b>
                      </label>
                      <DatePicker
                        views={["year", "month"]}
                        label={null}
                        minDate={new Date("2018-02-01")}
                        maxDate={new Date()}
                        value={selectDate}
                        onChange={(newValue) => {
                          setSelectDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="text-primary">
                      <b>Pesquisar por atleta:</b>
                    </label>
                    <Paper
                      component={'div'}    
                      className="form-control form-search my-4 w-100 p-3 "                   
                      sx={{ display: 'flex', alignItems: 'center'}}  >
                      <InputBase
                      sx={{ fontSize:'2rem'}}
                      type="category"                      
                      name="category"
                      id="category"
                      placeholder="Nome"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyDown={handleSearchKeyDown}>                      
                      </InputBase>
                      <IconButton
                      onClick={handleSearchClick}                                                          
                      type="button" sx={{ float:'right', marginRight:'-15px', width:'50px', height:'50px'}} aria-label="search">                                                        
                      </IconButton>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              {ranking.error ? (
                <Alert severity="error">
                  <span style={{ fontSize: "1.5rem" }}>
                    Nenhum dado encontrado.
                  </span>
                </Alert>
              ) : (
                <div className="ranking-wrapper w-100">
                  <div className="top-3 text-white bg-primary">
                    <div className="ranking-title flex-wrap">
                      <h2 className="mb-4 mr-8">
                        Ranking {activeCategoria ? activeCategoria : "Geral"}
                      </h2>
                      <h6>
                        atualizado em{" "}
                        {moment(selectDate).subtract(1, "month").daysInMonth()}/
                        {moment(selectDate)
                          .subtract(1, "month")
                          .format("MM/YYYY")}
                      </h6>
                    </div>
                    {(ranking.loading
                      ? Array.from(new Array(3))
                      : rankingTop
                    ).map((item, key) => (
                      <PlayerThree
                        key={key}
                        loading={ranking.loading}
                        atleta={item}
                      />
                    ))}
                  </div>
                  <div className="top-10 text-white">
                    {(ranking.loading
                      ? Array.from(new Array(3))
                      : ranking.payload
                    ).map((item, key) => (
                      <PlayerThree
                        key={key}
                        loading={ranking.loading}
                        atleta={item}
                      />
                    ))}

                    {ranking.hasMore ? (
                      <div className="mt-5 text-center">
                        <Button
                          className="btn-load-content"
                          onClick={() => fetchMoreData()}
                        >
                          Carregar mais
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </LocalizationProvider>
    </>
  );
}
