import { useEffect, useState } from "react";
import { governancaApi } from "../../api";
import OwlCarousel from "../features/owl-carousel";
import _ from 'lodash'
import { tipoDocumentos } from "../../assets/data/tipoDocumentos";

export default function FeatureBox() {
    const options = {
        items: 3,
        margin: 60,
        responsive: {
            992: {
                items: 3
            },
            576: {
                items: 2
            },
            0: {
                items: 1
            }
        }
    }

 

    const [documentos, setDocumentos] = useState([])
    const [documentosOrder, setDocumentosOrder] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setDocumentos([])
        async function getDocumentos(tipo) {
            await governancaApi.get('documento', {
                params: {
                    flag_del: 0,
                    id_tipo: tipo.id,
                    expand: 'arquivoDocumento',
                    sort: '-created_at',
                    pageSize: 2,
                },
            }).then((response) => {

                setDocumentos(documentos => [...documentos, { ...tipo, payload: response.data.items }]);
                if (tipo.id === 8) {
                    setTimeout(
                        () => {
                            setLoading(false)
                        },
                        1000
                    );

                }
            });
        } 
        tipoDocumentos.forEach((element) => {
            getDocumentos(element);
        });


    }, [])

    useEffect(() => {
        if (!loading) {
            // console.log(_.sortBy(documentos, ['id']))
            setDocumentosOrder(_.sortBy(documentos, ['id']))
        }
    }, [documentos, loading])




    return (
        <div className="feature-boxes">
            <div className="feature-background">
            </div>

            <div className="container">
                <OwlCarousel adClass="owl-theme" options={options}>
                    {!loading ?
                        documentosOrder.map(item => (
                            <div key={item.id} className="feature-box text-white">
                                <i class={item.icon}></i>
                                <h3>{item.descricao}</h3>
                                <div className="card-docs">
                                    {item.payload.map(item => (
                                        <p key={item.id} className="text-primary">
                                            {item.arquivoDocumento[0] ?
                                                <a target="_blank" href={`${process.env.REACT_APP_SGE_URL}_uploads/arquivoDocumento/${item.arquivoDocumento[0].nome_arquivo}`} rel="noreferrer">
                                                    {item.titulo}
                                                </a>
                                                : ""}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))
                        : ''
                    }
                </OwlCarousel>
            </div >
        </div >
    )
}