import { Grid } from "@mui/material";
import CardNoticia from "./CardNoticia";

export default function GridNoticias(props) {
  const { noticias } = props;
  if (noticias.payload.length === 0) {
    return null;
  }
  if (noticias.payload.length === 1) {
    return (
      <div className="grid-noticias">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <CardNoticia
              titleClass={"post-title"}
              noticia={noticias.payload[0]}
              loading={noticias.loading}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className="grid-noticias">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <CardNoticia
            titleClass={"post-title"}
            noticia={noticias.payload[0]}
            loading={noticias.loading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            {noticias.payload.slice(1, 5).map((el, index) => (
              <Grid item xs={12} sm={12} md={6}>
                <CardNoticia
                  titleClass={"post-title title-2"}
                  noticia={el}
                  loading={noticias.loading}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
