import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import { convertDateDayMonthString } from "../../../util/convertDate";
export default function EventTwo(props) {
    const { evento, loading } = props;

    const [color, setColor] = useState('light-primary');

    useEffect(() => {

        switch (evento.evento.escopo) {
            case "Nacional":
                setColor("secondary-dark")
                break;
            case "Internacional":
                setColor("bg-blue")
                break;
            case "Estadual":
                setColor("bg-green")
                break;
            case "Local":
                setColor("light-primary")
                break;
            default:
                break;
        }

    }, [evento])

    // const getLocal = (local) => {
    //     let _local = ""
    //     if (local) {
    //         _local = local.split('@')[1]
    //     }
    //     return _local
    // }
    // const getArena = (local) => {
    //     let _local = ""
    //     if (local) {
    //         _local = local.split('@')[0]
    //     }
    //     return _local
    // }

    return (
        <div className="event event-two">
            <Link to={loading ? "" : `/evento/${evento.evento.id}/${createSlug(evento.evento.descricao)}`}>
                <h2 className={`score bg-${color}`}>{loading ? "" : convertDateDayMonthString(evento.evento.data_inicio).replace('de ', '').toUpperCase()}</h2>

                {/* <h4 className={`text-${color}`}>{loading ? "" : getLocal(evento.evento.local)}</h4> */}

                <h3 className="text-primary">{loading ? "" : evento.evento.descricao}</h3>

                <h5 className="text-uppercase">{loading ? "" : evento.evento.local}</h5>
            </Link>
        </div>
    )
}