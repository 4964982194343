import { Alert, Button, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet'
import ReactInputMask from 'react-input-mask'
import { governancaApi } from '../../../api'
import ouvidoriaFields from '../../../assets/data/ouvidoriaFields'

import geraProtocolo from '../../../util/geraProtocolo'
import Breadcrumbs from '../../common/Breadcrumbs'

export default function ContatoPage(props) {
    const breadcrumb = [
        { label: 'Contato', url: '/contato', active: true },
    ]
    const [formValues, setFormValues] = useState(ouvidoriaFields)

    const [protocolo, setProtocolo] = useState("")

    const [notRobot, setNotRobot] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [storedCaptchaToken, setStoredCaptchaToken] = useState('');
    const [mensagemAnonima, setMensagemAnonima] = useState(false);

    const onChangeCaptcha = (value) => {
        setNotRobot(true);
        setCaptchaToken(value)
    };

    function validateForm(event) {
        event.preventDefault();
        if (notRobot && captchaToken) {
            if (captchaToken === storedCaptchaToken) {
                alert('Robô detectado')
            } else {
                var protocolo = geraProtocolo();
                var datenow = Math.round(new Date() / 1000);
                if (mensagemAnonima) {
                    setFormValues({ ...formValues, nome_solicitante: "anônimo", telefone: "", protocolo: protocolo, updated_at: datenow, created_at: datenow });
                } else {
                    setFormValues({ ...formValues, protocolo: protocolo, updated_at: datenow, created_at: datenow });
                }
                setProtocolo(protocolo)
                setStoredCaptchaToken(captchaToken)
            }
        } else {
            setSnackState({
                severity: "error",
                text: "Valide o captcha para enviar a mensagem!",
                open: true
            })
        }
    }

    const handleChangeValues = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };

    const [snackState, setSnackState] = useState({
        severity: '',
        text: '',
        open: false
    })

    const handleCloseSnack = () => {
        setSnackState({
            severity: '',
            text: '',
            open: false
        })
    }

    useEffect(() => {
        if (formValues.created_at !== '') {

            async function postFornecedor() {
                await governancaApi.post('solicitacao-ouvidoria', formValues
                ).then((response) => {
                    setFormValues({
                        ...formValues,
                        nome_solicitante: "",
                        telefone: "",
                        email: "",
                        motivo: "",
                        mensagem: "",
                        created_at: "",
                        updated_at: "",
                        created_by: "",
                        updated_by: ""
                    })
                    setSnackState({
                        severity: 'success',
                        text: "Mensagem enviada com sucesso!",
                        open: true
                    })
                    setPhone('')
                }).catch((err) => {
                    console.error(err)
                    setFormValues({
                        ...formValues,
                        created_at: "",
                        updated_at: "",
                    })
                    setSnackState({
                        severity: "error",
                        text: "Erro ao enviar mensagem. Verifique o preenchimento dos campos e tente novamente.",
                        open: true
                    })
                    setProtocolo('')
                });
            }
            postFornecedor()
        }
    }, [formValues.created_at, formValues, protocolo])

    const [phone, setPhone] = useState('')

    const changePhone = (e) => {
        var _phone = e.target.value
        setPhone(_phone)
        if (_phone) {
            setFormValues({ ...formValues, telefone: _phone.replace(/ /g, "").replace(/-/g, "").replace(/\(|\)/g, "") })
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contato `}</title>
            </Helmet>
            <Breadcrumbs paths={breadcrumb} />
            <div className="contato-page">
                <div className='bg-primary-light'>
                    <div className="container">
                        <div className="list-header">
                            <div className="section-title mb-0">
                                <h2 className="text-primary">ENTRE EM CONTATO</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card card-contato text-white bg-primary">
                        <div className="card-body">
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <h5 className="card-title">Contato</h5>
                                        <p>
                                            Confederação Brasileira de Boliche<br />
                                            Vinculada ao Comitê Olímpico Brasileiro
                                            <address>SEPS 705/905 Bloco “A” Sala 517 - Brasília - DF<br />
                                                CEP 70.390-055</address>
                                        </p>
                                        <div className="spacer-sm">
                                        </div>
                                        <p>
                                            Telefone: <a href="tel:+556133401340">+ 55 61 3340-1340</a><br />
                                            E-mail: <a href="mailto:contato@bolichebrasil.com.br">contato@bolichebrasil.com.br</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-8">
                                        <form onSubmit={validateForm} className="contact-form">
                                            <Alert severity="info" style={protocolo !== "" ? { marginBottom: '1rem' } : { display: 'none' }}>Seu protocolo é: {protocolo}</Alert>

                                            <div className="row">
                                                {mensagemAnonima ? '' :
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label" for="contact-form-name">
                                                                Nome {!mensagemAnonima ? <span className="required">
                                                                    *</span> : ''}
                                                            </label>
                                                            <input className="form-control" label="Nome" value={formValues.nome_solicitante} required={!mensagemAnonima} type="name" name="nome_solicitante" onChange={handleChangeValues} placeholder="Seu nome completo..." />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" for="contact-form-email">
                                                            Email <span className="required">
                                                                *</span>
                                                        </label>
                                                        <input className="form-control" label="Email" value={formValues.email} required type="email" name="email" onChange={handleChangeValues} placeholder="Seu email..." />
                                                    </div>
                                                </div>
                                                {mensagemAnonima ? '' :
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label" for="contact-form-email">
                                                                Telefone <span className="required">
                                                                    *</span>
                                                            </label>
                                                            <ReactInputMask
                                                                className="form-control"
                                                                mask="(99) 99999-9999"
                                                                value={phone}
                                                                onChange={changePhone}
                                                                name="telefone"
                                                                placeholder="(99) 99999-9999"
                                                                maskChar=" "
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" for="contact-form-subject">
                                                            Assunto</label>
                                                        <input className="form-control" type="text" label="Nome" value={formValues.motivo} required name="motivo" onChange={handleChangeValues} placeholder="Assunto da mensagem..." />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" for="contact-form-message">
                                                            Mensagem <span className="required">
                                                                *</span>
                                                        </label>
                                                        <textarea label="Mensagem" value={formValues.mensagem} required name="mensagem" onChange={handleChangeValues} rows="5" className="form-control" placeholder="Escreva sua mensagem aqui...">
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="mb-3 form-check">
                                                            <input type="checkbox" id="checkbox-anonimo" className="form-check-input" value={mensagemAnonima} onChange={() => setMensagemAnonima(!mensagemAnonima)} />
                                                            <label className="form-check-label" for="checkbox-anonimo">Deseja enviar de forma anônima?</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <ReCAPTCHA
                                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                            onChange={onChangeCaptcha}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group form-group--submit">
                                                <Button type="submit" variant="outlined" fullWidth color="inherit" className="btn-submit">
                                                    Enviar mensagem</Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackState.open}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={6000}
            >
                <Alert severity={snackState.severity} sx={{ width: '100%', fontSize: '1.35rem' }}>
                    {snackState.text}
                </Alert>
            </Snackbar>

        </React.Fragment>
    )
}
