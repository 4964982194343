import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { portalApi } from "../../../api";
import ReactHtmlParser from "react-html-parser";
import { convertDate } from "../../../util/convertDate";
import Breadcrumbs from "../../common/Breadcrumbs";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import ShareButtons from "./share-buttons/ShareButtons"; 

export default function SingleNoticias() {
  const { id, slug } = useParams();

  const [noticia, setNoticia] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (id) {
      setNoticia({
        loading: true,
        error: false,
        payload: [],
      });
      async function getNoticia() {
        await portalApi
          .get("noticia", {
            params: {
              id: id,
              expand: "idEsporte,user,idPessoa",
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setNoticia({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setNoticia({
                loading: false,
                error: false,
                payload: response.data.items[0],
              });
            }
          });
      }
      getNoticia();
    }
  }, [id]);

  // const imgPostFallback = (e) => {
  //   // e.target.src = `${process.env.PUBLIC_URL}/assets/images/logos/boliche-brasil.webp`;
  //   // e.target.style = 'filter: grayscale(1); object-fit: contain; opacity:0.8;width: 100%; height:400px;'
  //   e.target.style = "display:none;";
  // };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.src) {
        return <React.Fragment></React.Fragment>;
      }
    },
  };

  const breadcrumb = [
    { label: "noticias", url: "/noticias" },
    { label: noticia.payload.titulo ? noticia.payload.titulo : "", url: "" },
  ];

  return (
    <>
      {noticia.loading || noticia.error ? (
        ""
      ) : (
        <Helmet>
          <title>
            {noticia.payload.titulo} - {process.env.REACT_APP_SITETITLE}
          </title>
        </Helmet>
      )}
      <Breadcrumbs paths={breadcrumb} />
      <main className="main noticias-single">
        <div className="container">
          <div className="row noticias-desc">
            <div className="col-md-8">
              <h2>{noticia.loading ? "" : noticia.payload.titulo}</h2>

              {noticia.payload.resumo === noticia.payload.corpo ? (
                ""
              ) : (
                <p>
                  {noticia.loading
                    ? ""
                    : parse(
                      noticia.payload.resumo.replace(/&nbsp;/g, ""),
                      options
                    )}
                </p>
              )}
              {/* <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                <h6>COMPARTILHE: </h6>
                <ShareButtons title={noticia.payload.titulo} text={noticia.payload.resumo} url={`noticias/leitura/${id}/${slug}`} />
              </div> */}
            </div>
            <div className="col-md-4">
              <div className="noticias-sec">
                <h6>Postado em:</h6>
                <h5>
                  {noticia.loading
                    ? ""
                    : convertDate(noticia.payload.created_at * 1000)
                      .replaceAll(".", "")
                      .toUpperCase()}
                </h5>
                <h6 className="text-uppercase">menos de 1 minuto de leitura</h6>
              </div>

              <div className="noticias-sec">
                <h6>Postado por:</h6>
                <h5>
                  {noticia.loading
                    ? ""
                    : noticia.payload.user.idPessoa.nome_completo}
                </h5>
              </div>
              <div className="noticias-sec border-0">
                <div className="btn-wrapper">
                  <span className="btn btn-link text-uppercase text-secondary">
                    {noticia.loading ? "" : noticia.payload.idEsporte.descricao}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {noticia.loading || noticia.error ? (
            ""
          ) : (
            <figure className="post-media">
              <img
                src={`${process.env.REACT_APP_SGE_URL}${noticia.payload.urlFotoCapa}`}
                alt={noticia.payload.idEsporte.descricao}
              />
            </figure>
          )}
          <div className="post-desc">
            {noticia.loading || noticia.error
              ? ""
              : ReactHtmlParser(noticia.payload.corpo)}
          </div>
        </div>
        <div className="container">
          <div className="space-y-2">
            <div className="share-section">
              <span className="text-xs font-semibold text-white">
                COMPARTILHE:
              </span>
              <ShareButtons title={noticia.payload.titulo} text={noticia.payload.resumo} url={`noticias/leitura/${id}/${slug}`} />
            </div>
            <div className="bigmidia-section">
              <p className="font-semibold">
                Publicado pela Plataforma SGE da Bigmidia - Gestão Esportiva
                com Tecnologia
              </p>
              <p className="text-sm">
                A Plataforma SGE é um Sistema de Gestão Esportiva desenvolvido
                para Confederações e Federações Esportivas. Saiba tudo sobre o
                funcionamento de um sistema de gestão esportiva e{' '}
                <a
                  className="underline"
                  target="_blank" rel="noreferrer"
                  href={process.env.REACT_APP_SGE_URL}
                >
                  conheça melhor o SGE!
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
