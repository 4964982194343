import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import { convertDateDayMonthString } from "../../../util/convertDate";

export default function EventOne(props) {
    const { evento, loading } = props;

    const imgPostFallback = (e) => {
        e.target.src = `${process.env.PUBLIC_URL}/assets/images/logos/boliche-brasil.webp`;
        e.target.style = 'filter: grayscale(1); object-fit: contain; opacity:0.8;width: 100%;'
        e.target.parentNode.style = 'background:#fff';
    }

    // const getLocal = (local) => {
    //     let _local = ""
    //     if (local) {
    //         _local = local.split('@')[1]
    //     }
    //     return _local
    // }
    // const getArena = (local) => {
    //     let _local = ""
    //     if (local) {
    //         _local = local.split('@')[0]
    //     }
    //     return _local
    // }

    return (
        <article className="event event-one">
            <Link to={loading ? "" : `/evento/${evento.evento.id}/${createSlug(evento.evento.descricao)}`}>
                <figure className="event-media">
                    {!loading ?
                        <img src={`${process.env.REACT_APP_SGE_URL}_uploads/relatorioEvento/${evento.evento.arte_evento}`} onError={(e) => imgPostFallback(e)} alt="event" />
                        :
                        <Skeleton variant="rectangular" width={'100%'} height={240} />
                    }
                    <h4 className="score">{loading ? "" : convertDateDayMonthString(evento.evento.data_inicio).replace('de ', '').toUpperCase()}</h4>
                </figure>

                <div className="event-detail">
                    {/* <h4 className="text-green">{loading ? "" : getLocal(evento.evento.local)}</h4> */}
                    <h3 className="event-title text-primary">{loading ? "" : evento.evento.descricao}</h3>
                    <h5 className="text-uppercase text-gray">{loading ? "" : evento.evento.local}</h5>
                </div>
            </Link>
        </article>
    )
}