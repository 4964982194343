import { Alert, Skeleton } from "@mui/material";
import React from "react";
import EstadoFlag from "../../../../assets/img/flags/EstadosFlag";

function Partidas(props) {
  const {
    partidas,
    // eventoCategoria, handleChangeCategoria, idCategoria
  } = props;

  const testWinner = (pts1, pts2) => {
    if (pts1 > pts2) {
      return "vencedor";
    } else {
      return "";
    }
  };

  const handleFlags = (atleta) => {
    let uf = atleta.uf ? atleta.uf.split(",")[0] : "";
    return uf;
  };

  return (
    <div className="partidas-section">
      <div className="row">
        <div className="col-md-6">
          <h2 className="partidas-title">Finais</h2>
        </div>
      </div>
      {partidas.error ? (
        <Alert severity="warning">
          <span style={{ fontSize: "1.5rem" }}>Nenhuma partida encontrada.</span>
        </Alert>
      ) : (
        <div className="table-responsive">
          {(partidas.loading ? Array.from(new Array(2)) : partidas.payload).map((item, key) => (
            <table key={key} className="table table-partida">
              <thead>
                <tr>
                  <th colSpan="3">
                    <h4 className="table-title">
                      {partidas.loading ? (
                        <Skeleton variant="text" width={100} style={{ margin: "0 auto" }} />
                      ) : (
                        item.descricao
                      )}
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(partidas.loading ? Array.from(new Array(2)) : item.eventoCategoriaPartidas).map((item, key) => (
                  <tr key={key}>
                    <td>
                      {partidas.loading ? (
                        <div className="d-flex align-items-center">
                          <Skeleton variant="rectangular" width={100} height={50} style={{ marginRight: 10 }} />
                          <Skeleton variant="text" width={"100%"} />
                        </div>
                      ) : (
                        <div className="atleta justify-content-end">
                          {item.atleta1.nomes}
                          <div className="mx-2">
                            <EstadoFlag className="mx-2" estado={handleFlags(item.atleta1)} />
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="td-placar">
                      {partidas.loading ? (
                        <div className="placar">
                          <Skeleton variant="text" width={"50%"} />
                          x
                          <Skeleton variant="text" width={"50%"} />
                        </div>
                      ) : (
                        <div className="placar">
                          <span className={`pts ${testWinner(item.linha1, item.linha2)}`}>{item.linha1}</span> x{" "}
                          <span className={`pts ${testWinner(item.linha2, item.linha1)}`}>{item.linha2}</span>
                        </div>
                      )}
                    </td>
                    <td>
                      {partidas.loading ? (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                          <Skeleton variant="text" width={"100%"} />
                          <Skeleton variant="rectangular" width={100} height={50} style={{ marginLeft: 10 }} />
                        </div>
                      ) : (
                        <div className="atleta justify-content-start">
                          <div className="mx-2">
                            <EstadoFlag estado={handleFlags(item.atleta2)} />
                          </div>
                          {item.atleta2.nomes}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      )}
    </div>
  );
}

export default Partidas;
