import {
  Alert,
  Avatar,
  Box,
  Collapse,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../util/createSlug";
import { ConfedApi } from "../../../../api";
import { AddCircleSVG, RemoveCircleSVG } from "../../../../assets/icons";
import EstadoFlag from "../../../../assets/img/flags/EstadosFlag";

function TabelaInscritos({ idEvento }) {
  const [eventoAtleta, setEventoAtleta] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  useEffect(() => {
    setEventoAtleta({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoAtleta() {
      await ConfedApi.get("evento-atleta/inscritos", {
        params: {
          id_evento: idEvento,
        },
      }).then((response) => {
        const resData = _.toArray(response.data);
        if (resData.length === 0) {
          setEventoAtleta({
            loading: false,
            error: true,
            payload: [],
          });
        } else {
          setEventoAtleta({
            loading: false,
            error: false,
            payload: resData,
          });
        }
      });
    }
    getEventoAtleta();
  }, [idEvento]);

  const [openTable, setOpenTable] = useState(false);
  const handleExpandTable = (target, index) => {
    setOpenTable({ [index]: !openTable[index] });
  };

  return (
    <div>
      <div className="pb-5">
        <TableContainer>
          <Table size="small" className="table-template">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>CLUBE</TableCell>
                <TableCell>UF</TableCell>
                <TableCell align="center">Bolas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventoAtleta.loading &&
                Array.from(new Array(5)).map((item, key) => (
                  <TableRow key={key}>
                    <TableCell colSpan={4}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              {eventoAtleta.error && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Alert severity="error" sx={{ fontSize: "15px" }}>
                      Nenhum atleta encontrado.
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
              {eventoAtleta.payload.map((item) => (
                <React.Fragment key={item.id_atleta}>
                  <TableRow>
                    <TableCell>
                      <Box className="box-atleta">
                        <Avatar src={`${process.env.REACT_APP_SGE_URL}/_uploads/fotoAtleta/${item.foto}`} />
                        <Link to={`/atleta/${item.id_atleta}/${createSlug(item.nome_evento)}`}>
                          <Typography variant="subtitle1">{item.nome_evento}</Typography>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="box-atleta">
                        {
                          item.equipe?.length ? 
                            <>
                              <Avatar
                                variant="rounded"
                                src={`${process.env.REACT_APP_SGE_URL}/_uploads/estabelecimento/equipe/logos/${item.equipe_logo ?? 'noLogo.png'}`}
                              />
                              <Typography variant="subtitle1">{item.equipe}</Typography>
                            </>
                          : 
                            <>
                              <Avatar
                                variant="rounded"
                                src={`${process.env.REACT_APP_SGE_URL}/_uploads/estabelecimento/logo/${item.logo ?? 'noLogo.png'}`}
                              />
                              <Typography variant="subtitle1">{item.clube}</Typography>
                            </>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <EstadoFlag estado={item.uf} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        size="small"
                        disabled={Boolean(item.bolas && !item.bolas.length)}
                        onClick={(e) => handleExpandTable(e.currentTarget, item.id_atleta)}
                      >
                        {openTable[item.id_atleta] ? (
                          <RemoveCircleSVG sx={{ fontSize: "20px" }} />
                        ) : (
                          <AddCircleSVG sx={{ fontSize: "20px" }} />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ p: 0 }} colSpan={6}>
                      <Collapse in={openTable[item.id_atleta]} timeout="auto" unmountOnExit>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mb: { md: 2 },
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#F6FAFF",
                              width: { xs: "100%", md: "60%" },
                              p: { xs: 2, md: "1.2rem 3rem" },
                            }}
                          >
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Bola</TableCell>
                                  <TableCell>Marca</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.bolas.map((item) => (
                                  <TableRow key={item.bola_nome}>
                                    <TableCell>
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                          src={`${process.env.REACT_APP_SGE_URL}/_uploads/bolas/${item.foto_bola}`}
                                          sx={{ mr: 1, height: 30, width: 30 }}
                                        />
                                        <Typography fontSize="inherit">{item.bola_nome}</Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>{item.marca_bola}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default TabelaInscritos;
