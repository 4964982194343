import React from 'react'
import { useNoticiasEvento } from '../hooks/useNoticiasEvento';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Link, Skeleton, Typography } from '@mui/material';
import { createSlug } from '../../../../../util/createSlug';

function NoticiasRelacionadas({ idEvento }) {
  const { loading, error, data } = useNoticiasEvento(idEvento);

  if (error) {
    return <></>
  }
  return (
    <div>
      <Typography fontSize={25} fontWeight={600} color="#00287b" mb={3} ml={{ xs: 2, sm: 0 }} fontFamily="'Lato', sans-serif">Notícias Relacionadas</Typography>
      <Grid container spacing={6}>
        {loading ?
          Array.from(new Array(3)).map((item, key) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Skeleton height={185} variant='rectangular' />
              <Skeleton height={20} variant='text' />
              <Skeleton height={20} width={'60%'} variant='text' />
            </Grid>
          ))
          : data.map((item, key) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Card elevation={0}>
                <CardActionArea to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}
                  component={Link}>
                  <CardMedia
                    component={"img"}
                    image={`${process.env.REACT_APP_SGE_URL}/${item.urlFotoCapa}`}
                    alt={item.titulo} />
                  <CardContent>
                    <Typography fontSize={16} fontWeight={500} color="primary">{item.titulo}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default NoticiasRelacionadas