import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import { convertDateDayMonthString } from "../../../util/convertDate";
import { formatDatePeriod } from "../../../util/formatDatePeriod";
import { Skeleton } from "@mui/material";

export default function EventTableRow(props) {
  const { evento, boliche, municipio, estabelecimento, loading } = props;

  // console.log(boliche);
  return (
    <tr className="event event-three">
      <td
        className={`score evento-${loading ? "" : evento.escopo.toLowerCase()}`}
      >
        <h5>
          <span>
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              convertDateDayMonthString(evento.data_inicio)
                .replace("de ", "")
                .toUpperCase()
            )}
          </span>
        </h5>
      </td>
      <td className="event-title" style={{ maxWidth: 400 }}>
        <div className="container-border">
          <div className="text-wrapper">
            <h5>
              {loading ? (
                <Skeleton variant="text" />
              ) : (
                <Link
                  to={`/evento/${evento.id}/${createSlug(evento.descricao)}`}
                >
                  {evento.descricao}
                </Link>
              )}
            </h5>
            <h6>
              {loading ? (
                <Skeleton variant="text" />
              ) : (
                formatDatePeriod(evento.data_inicio, evento.data_fim)
              )}
            </h6>
          </div>
        </div>
      </td>
      <td>
        <div className="container-border">
          <div className="text-wrapper text-center">
            <h5>
              <b>
                {loading ? <Skeleton variant="text" /> : estabelecimento.sigla}
              </b>
            </h5>
          </div>
        </div>
      </td>
      <td style={{ maxWidth: 200 }}>
        <div className="container-border">
          <div className="text-wrapper text-center">
            <h5>
              <b>
                {loading ? (
                  <Skeleton variant="text" />
                ) : boliche == null ? (
                  ""
                ) : (
                  boliche.descricao
                )}
              </b>
            </h5>
            {municipio == null || !municipio.descricao
              ? ""
              : municipio.descricao + "/" + municipio.uf}
          </div>
        </div>
      </td>
      <td>
        <div className="container-border">
          <div className="text-wrapper text-center">
            <h5>
              <b>{loading ? <Skeleton variant="text" /> : evento.escopo}</b>
            </h5>
          </div>
        </div>
      </td>
    </tr>
  );
}
