import { Collapse } from "@mui/material";
import { useState } from "react";
import { PlusCircleSVG } from "../../../assets/icons";

export default function Card(props) {
    const { isExpanded, title } = props;
    const [expanded, setExpanded] = useState(Boolean(isExpanded));
    return (
        <div className={`card border-0 card-one ${expanded ? '' : 'collapsed'}`}>
            <div className="card-title text-upppercase" onClick={() => setExpanded(!expanded)}>
                <h2>
                    <PlusCircleSVG viewBox="-0.5 -0.5 26.5 26.5"/>
                    {title}
                </h2>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className="card-body">
                    {props.children}
                </div>
            </Collapse>
        </div>
    )
}