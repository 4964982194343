import { Pagination, PaginationItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ConfedApi } from "../../../api";
import escopoOptions from "../../../assets/data/escopos";
import temporadas from "../../../assets/data/temporadas";
import EventTableRow from "../../features/events/EventTableRow";
import { Route } from "react-router";
import Breadcrumbs from "../../common/Breadcrumbs";
import { Helmet } from "react-helmet";
import moment from "moment";

export default function Eventos(params) {
  const sectionTop = useRef(null);
  const queryParam = params.queryParam;
  const temporada = params.temporada || moment().format("YYYY");
  const escopo = params.escopo;
  const page = params.page;

  const [temporadaEvento, setTemporadaEvento] = useState(temporada);
  const [escopoEvento, setEscopoEvento] = useState(escopo);
  const [searchTerm, setSearchTerm] = useState(queryParam);

  let history = useHistory();

  const [eventos, setEventos] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setEventos({
      loading: true,
      error: false,
      payload: [],
    });

    let params = {
      "evento.flag_del": 0,
      id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
      expand: "evento,estabelecimento,boliche,municipio",
      forcejoin: "evento",
      sort: "-evento.data_inicio",
      "evento.escopo": escopo,
      betweenField: "evento.data_inicio",
      betweenValue1: temporada.concat("-01-01"),
      betweenValue2: temporada.concat("-12-31"),
      page: page,
      pageSize: 200,
    };

    if (queryParam) {
      params.likeField = "evento.descricao";
      params.likeValue = queryParam;
    }

    async function getPartidasDestaque() {
      await ConfedApi.get("evento-regra", {
        params: params,
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventos({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEventos({
              loading: false,
              error: false,
              payload: response.data.items,
            });
            setTotalPages(response.data._meta.pageCount);
          }
        })
        .catch((err) => {
          setEventos({
            loading: false,
            error: true,
            payload: [],
          });
          //console.log(err);
        });
    }
    getPartidasDestaque();
  }, [page, queryParam, escopo, temporada]);

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm) {
        history.push(`/eventos?q=${searchTerm}`);
      } else {
        history.push("/eventos");
      }
    }
  };

  //selects

  const handleChangeTemporada = (event) => {
    setTemporadaEvento(event.target.value);
    if (queryParam || escopo) {
      history.push(
        `/eventos${
          event.target.value ? `?temporada=${event.target.value}&` : "?"
        }${escopo ? `escopo=${escopo}&` : ""}${
          queryParam ? `q=${queryParam}` : ""
        }`
      );
    } else {
      history.push(
        `/eventos${
          event.target.value ? `?temporada=${event.target.value}` : ""
        }`
      );
    }
  };

  const handleChangeEscopo = (event) => {
    setEscopoEvento(event.target.value);
    if (queryParam || temporada) {
      history.push(
        `/eventos${temporada ? `?temporada=${temporada}&` : "?"}${
          event.target.value ? `escopo=${event.target.value}&` : ""
        }${queryParam ? `q=${queryParam}` : ""}`
      );
    } else {
      history.push(
        `/eventos${event.target.value ? `?escopo=${event.target.value}` : ""}`
      );
    }
  };

  const breadcrumb = [{ label: "eventos", url: "/eventos" }];

  return (
    <>
      <Helmet>
        <title>Eventos - {process.env.REACT_APP_SITETITLE}</title>
      </Helmet>
      <Breadcrumbs paths={breadcrumb} />
      <div className="event event-group-two mt-5">
        <div ref={sectionTop}></div>
        <div className="event-group-header">
          <div
            className="event-header-background"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backs/evento.svg)`,
            }}
            alt="evento"
          />

          <div className="container-fluid">
            <h3 style={{ marginRight: "auto" }}>
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-3"
              >
                <path
                  d="M16.4505 1.22998V5.98398M6.9425 1.22998V5.98398M1 10.738H22.393M3.377 3.60698H20.016C21.3288 3.60698 22.393 4.6712 22.393 5.98398V22.623C22.393 23.9358 21.3288 25 20.016 25H3.377C2.06422 25 1 23.9358 1 22.623V5.98398C1 4.6712 2.06422 3.60698 3.377 3.60698Z"
                  stroke="#256AC3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Calendário
            </h3>
            <div className="d-md-flex mx-md-auto">
              <select
                name="category"
                className="form-control form-select select-year"
                value={temporadaEvento}
                onChange={handleChangeTemporada}
              >
                {temporadas.map((item, key) => (
                  <option key={key} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                name="category"
                className="form-control form-select select-type ms-md-4 "
                onChange={handleChangeEscopo}
                value={escopoEvento}
              >
                <option value="">tipo</option>
                {escopoOptions.map((item, key) => (
                  <option key={key} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>

              <input
                type="category"
                className="form-control form-search bg-white ms-md-4 "
                name="category"
                id="category"
                placeholder="pesquisar"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchKeyDown}
              />
            </div>
          </div>
        </div>

        <div className="events-list">
          <div className="container-fluid">
            <table className="w-100">
              <thead>
                <tr>
                  <th>DATA</th>
                  <th>EVENTO</th>
                  <th>ORGANIZADOR</th>
                  <th>LOCAL</th>
                  <th>TIPO</th>
                </tr>
              </thead>
              {eventos.error ? (
                <tr>
                  <td colSpan={4}>
                    <div className="alert alert-warning mx-2" role="alert">
                      <h4>
                        Nenhum evento encontrado para os filtros selecionados
                      </h4>
                    </div>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {(eventos.loading
                    ? Array.from(new Array(5))
                    : eventos.payload
                  ).map((item, key) => (
                    <EventTableRow
                      key={key}
                      evento={eventos.loading ? {} : item.evento}
                      boliche={eventos.loading ? {} : item.boliche}
                      municipio={
                        eventos.loading || item.boliche == null
                          ? {}
                          : item.boliche.municipio
                      }
                      estabelecimento={
                        eventos.loading || item.evento.estabelecimento == null
                          ? {}
                          : item.evento.estabelecimento
                      }
                      loading={eventos.loading}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div className="pagination-wrapper">
          <div className="container-fluid">
            {totalPages > 1 ? (
              <Route>
                {({ location }) => {
                  const query = new URLSearchParams(location.search);
                  const page = parseInt(query.get("p") || "1", 10);

                  return (
                    <Pagination
                      className="pagination"
                      page={page}
                      count={totalPages}
                      size="small"
                      renderItem={(item) => {
                        if (queryParam || escopo || temporada) {
                          return (
                            <PaginationItem
                              component={Link}
                              to={`/eventos?${
                                temporada ? `temporada=${temporada}&` : ""
                              }${escopo ? `escopo=${escopo}&` : ""}${
                                queryParam ? `q=${queryParam}&` : ""
                              }${item.page === 1 ? "" : `p=${item.page}`}`}
                              {...item}
                            />
                          );
                        } else {
                          return (
                            <PaginationItem
                              component={Link}
                              to={`/eventos${
                                item.page === 1 ? "" : `?p=${item.page}`
                              }`}
                              {...item}
                            />
                          );
                        }
                      }}
                    />
                  );
                }}
              </Route>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
