import { Avatar } from "@mui/material";

import AC from "./AC.png";
import AL from "./AL.png";
import AM from "./AM.png";
import AP from "./AP.png";
import BA from "./BA.png";
import CE from "./CE.png";
import DF from "./DF.png";
import ES from "./ES.png";
import GO from "./GO.png";
import MA from "./MA.png";
import MG from "./MG.png";
import MS from "./MS.png";
import MT from "./MT.png";
import PA from "./PA.png";
import PB from "./PB.png";
import PE from "./PE.png";
import PI from "./PI.png";
import PR from "./PR.png";
import RJ from "./RJ.png";
import RN from "./RN.png";
import RO from "./RO.png";
import RR from "./RR.png";
import RS from "./RS.png";
import SC from "./SC.png";
import SE from "./SE.png";
import SP from "./SP.png";
import TO from "./TO.png";

export default function EstadoFlag(props) {
  const { estado, size } = props;

  const getUFFlag = (UF) => {
    switch (UF) {
      case "AC":
        return AC;
      case "AL":
        return AL;
      case "AM":
        return AM;
      case "AP":
        return AP;
      case "BA":
        return BA;
      case "CE":
        return CE;
      case "DF":
        return DF;
      case "ES":
        return ES;
      case "GO":
        return GO;
      case "MA":
        return MA;
      case "MG":
        return MG;
      case "MS":
        return MS;
      case "MT":
        return MT;
      case "PA":
        return PA;
      case "PB":
        return PB;
      case "PE":
        return PE;
      case "PI":
        return PI;
      case "PR":
        return PR;
      case "RJ":
        return RJ;
      case "RN":
        return RN;
      case "RO":
        return RO;
      case "RR":
        return RR;
      case "RS":
        return RS;
      case "SC":
        return SC;
      case "SE":
        return SE;
      case "SP":
        return SP;
      case "TO":
        return TO;
      default:
        return "";
    }
  };

  return (
    <>
      {Boolean(getUFFlag(estado)) ? (
        <Avatar
          style={size ? size : { width: 50, height: 34 }}
          src={getUFFlag(estado)}
          variant="square"
          alt={estado}
          title={estado}
          className='border-flag'
        />
      ) : (
        <></>
      )}
    </>
  );
}
