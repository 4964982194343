import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../common/Breadcrumbs'

export default function PageNotFound() {
    const breadcrumb = [
		{ label: "", url: "" }
	]
    
    return (
        <React.Fragment>
            <Breadcrumbs paths={breadcrumb} />
            <main className="main ranking-page">
                <div className="ranking-list ranking-list-one">
                    <div className="bg-primary-light">

                        <div className="error-404 py-5 text-center">
                            <div className="row">
                                <div className="col-md-8 offset-md-2"> 
                                    <h1 style={{ fontSize: 170, opacity: 0.4 }}>404</h1>
                                    <header className="error__header">
                                        <h2 className="error__title mt-3 mb-5" style={{fontSize:'2.5rem'}}>Página não encontrada</h2>
                                        
                                    </header>
                                    <div className="error__description" style={{fontSize:'1.5rem'}}>
                                    A página que você está procurando foi movida ou não existe mais, se desejar, pode retornar à nossa página inicial.
                                    </div>
                                    <footer className="error__cta my-5">
                                        <Link to="/" className="btn btn-primary" style={{fontSize:'1.75rem', padding:'6px 18px'}}>Voltar para Home</Link>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}
