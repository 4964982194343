import { useState } from "react";
import { useEffect } from "react";
import { gestaoApi } from "../../../api";
import OwlCarousel from "../../features/owl-carousel";
import PostFour from "../../features/posts/post-four";

export default function BlogSection() {
    const [blogPosts, setBlogPosts] = useState({
        loading: true,
        error: false,
        payload: []
    });

    useEffect(() => {
        setBlogPosts({
            loading: true,
            error: false,
            payload: []
        })
        async function getRankingCategorias() {
            await gestaoApi.get('blog-bigmidia/busca-posts', {
                params: {
                    tags: 'cliente',
                    autoplay: true,
                    // pageSize: 6
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setBlogPosts({
                        loading: false,
                        error: true,
                        payload: []
                    })
                } else {
                    setBlogPosts({
                        loading: false,
                        error: false,
                        payload: response.data.items
                    })
                }
            });
        }
        getRankingCategorias()
    }, [])

    const options = {
        items: 3,
        margin: 60,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 6000,
        loop: false,
        responsive: {
            992: {
                items: 3
            },
            576: {
                items: 2
            },
            0: {
                items: 1
            }
        }
    }

    return (
        <div className="blog-section">
            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/blog-logo.svg`} alt="blog-logo" className="blog-logo" />
            {blogPosts.loading || blogPosts.error ? "" :
                <OwlCarousel adClass="owl-theme" options={options}>
                    {blogPosts.payload.map((item, key) => (
                        <PostFour key={key} noticia={item} url="assets/images/blog/post-1.png" />
                    ))}
                </OwlCarousel>
            }

        </div>
    )
}