import { Avatar, Button, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import _ from "lodash";
import { convertDate } from "../../../util/convertDate";
import EstadoFlag from "../../../assets/img/flags/EstadosFlag";
import SwipeableViews from "react-swipeable-views";

export default function PlayerThree(props) {
  const { number, atleta, color, loading } = props; 

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [ptsPorEvento, setPtsPorEvento] = useState([]);

  useEffect(() => {
    if (atleta) {
      let ptsEventos = atleta.evento_pontos.split(",");

      let ptsmap = ptsEventos.map((item, key) => {
        let itemSplit = item.split(":");
        let filterEvento = _.filter(
          JSON.parse(atleta.eventosInfo),
          function (o) {
            return o.id === Number(itemSplit[0]);
          }
        );

        return {
          id_evento: itemSplit[0],
          pts: itemSplit[1],
          ...filterEvento[0],
        };
      });
      let ordered = _.orderBy(ptsmap, ["data_inicio"], ["desc"]);
      let chunk = _.chunk(ordered, 3);
      setPtsPorEvento(chunk);
    }
  }, [atleta]);

  const [posColor, setPosColor] = useState("");
  //test color
  useEffect(() => {
    if (!loading) {
      if (color) {
        setPosColor(color);
      } else {
        switch (Number(atleta.colocacao)) {
          case 1:
            setPosColor("green");
            break;
          case 2:
            setPosColor("light");
            break;
          case 3:
            setPosColor("secondary");
            break;
          default:
            setPosColor("");
            break;
        }
      }
    }
  }, [atleta, color, loading]);

  return (
    <div className="player player-three">
      <div className="player-info d-lg-flex">
        <div className="d-flex align-items-center w-100">
          <div className={`player-number bg-${posColor}`}>
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              <h2>{number ? number : atleta.colocacao}</h2>
            )}
          </div>

          <div className="player-avatar">
            {loading ? (
              <Skeleton variant="circular" />
            ) : (
              <Avatar
                variant="circle"
                src={`${process.env.REACT_APP_SGE_URL}_uploads/fotoAtleta/${atleta.foto}`}
                alt={atleta.foto ? atleta.nome_completo : ""}
              />
            )}
          </div>

          <div className="player-name">
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              <Link
                to={`/atleta/${atleta.id_atleta}/${createSlug(atleta.nome_evento)}`}
              >
                {atleta.nome_evento}
              </Link>
            )}
          </div>
          <div className="player-nation ml-auto">
            {loading ? (
              <Skeleton variant="rectangular" height={34} width={50} />
            ) : (
              <EstadoFlag estado={atleta.uf ? atleta.uf : ""} />
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end atleta-pts-wrapper ml-auto">
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <React.Fragment>
              <Button
                className="btn-swipe-view"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <i className="fa-solid fa-angle-left"></i>
              </Button>
              <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                className="w-100"
              >
                {ptsPorEvento.map((item, key) => (
                  <div
                    key={key}
                    className="d-flex align-items-center"
                    style={{ height: 35 }}
                  >
                    {item.map((item, key) => (
                      <div
                        key={key}
                        className={`player-score text-center ${
                          key === 2 ? "border-0" : ""
                        }`}
                      >
                        <Link
                          to={`/evento/${item.id_evento}/${createSlug(
                            item.descricao
                          )}`}
                        >
                          <h3>{item.pts}</h3>
                          <h6>{convertDate(item.data_inicio)}</h6>
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </SwipeableViews>

              <Button
                className="btn-swipe-view"
                onClick={handleNext}
                disabled={activeStep === ptsPorEvento.length - 1}
              >
                <i className="fa-solid fa-angle-right"></i>
              </Button>
            </React.Fragment>
          )}
          <div className="total-score text-secondary d-block">
            <h3>{loading ? <Skeleton variant="text" /> : atleta.pontos}</h3>
          </div>
        </div>
      </div>
      <hr className="text-white m-0" />
    </div>
  );
}
