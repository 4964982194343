import { Card, CardActionArea, CardMedia, Typography } from "@mui/material";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";

export default function CardNoticia(props) {
  const { noticia, loading, titleClass } = props;
  if (!loading && noticia) {
    return (
      <Card className="post">
        <CardActionArea
          to={`/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`}
          component={Link}
        >
          <CardMedia
            className="post-media"
            component={"img"}
            image={`${process.env.REACT_APP_SGE_URL}/${noticia.urlFotoCapa}`}
            alt={noticia.titulo}
          />
          <div className="card-text">
            <div>
              <Typography className="post-date" variant="caption">
                {moment(noticia.created_at * 1000).format("DD/MM/YYYY")}
              </Typography>
              <Typography className={titleClass} variant="h4" component="h1">
                {noticia.titulo}
              </Typography>
            </div>
          </div>
        </CardActionArea>
      </Card>
    );
  }
  return null;
}
