import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../common/Breadcrumbs';

export default function PageEmBreve() {
    const breadcrumb = [
		{ label: "Em breve", url: "" }
	]
    
    return (
        <React.Fragment>
            <Breadcrumbs paths={breadcrumb} />
            <main className="main ranking-page">
                <div className="ranking-list ranking-list-one">
                    <div className="bg-primary-light">

                        <div className="error-404 py-5 text-center">
                            <div className="row">
                                <div className="col-md-8 offset-md-2"> 
                                    <i className="fa-solid fa-screwdriver-wrench" style={{ fontSize: 300, opacity: 0.4 }}></i>
                                    <header className="error__header">
                                        <h2 className="error__title my-3" style={{fontSize:'2.5rem'}}>Em breve!</h2>
                                        <h3 className="error__subtitle mb-2" style={{fontSize:'2rem'}}>Página em construção.</h3>
                                    </header>
                                    <div className="error__description" style={{fontSize:'1.5rem'}}>
                                        A página que você está procurando ainda não está disponivel.
                                    </div>
                                    <footer className="error__cta my-5">
                                        <Link to="/" className="btn btn-primary" style={{fontSize:'1.75rem', padding:'6px 18px'}}>Voltar para Home</Link>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}