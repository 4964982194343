import moment from 'moment'
import React from 'react'
export const formatDatePeriod = (start, end) => {

    // let startString = new Date(start).toLocaleString('pt-br', { month: '2-digit', day: '2-digit', year: 'numeric' })
    // let endString = new Date(end).toLocaleString('pt-br', { month: '2-digit', day: '2-digit', year: 'numeric' })

    // let startMonth = new Date(start).toLocaleString('pt-br', { month: '2-digit', year: 'numeric' })
    // let endMonth = new Date(end).toLocaleString('pt-br', { month: '2-digit', year: 'numeric' })

    // let startYear = new Date(start).toLocaleString('pt-br', { year: 'numeric' })
    // let endYear = new Date(end).toLocaleString('pt-br', { year: 'numeric' })


    return (
        <React.Fragment>
            {moment(start).format("DD/MM/YYYY")} à {moment(end).format("DD/MM/YYYY")}
        </React.Fragment>
    )
}