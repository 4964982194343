import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

const options = {
    settings: {
        autoplaySpeed: 4000,
        boxShadow: 'none',
    },
    buttons: {
        showDownloadButton: false,
    }
}

export default function Gallery(props) {
    const { galeria } = props;

    return (
        <SimpleReactLightbox>
            <SRLWrapper options={options}>
                <div className="gallery">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="section-title text-primary">
                                <h2>GALERIA<br />DE FOTOS</h2>

                                <div className="btn-wrapper y-center border-primary btn-out">
                                    <Link to="/galerias" className="text-primary">VER MAIS FOTOS</Link>
                                </div>
                            </div>
                        </div>


                        {galeria.loading || galeria.error ?
                            <div className="row m-0">
                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-2.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-3.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-4.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-5.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-6.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-7.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-8.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-1.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-2.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-3.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-4.png`} alt="event" />
                                </div>

                                <div className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/events/event-5.png`} alt="event" />
                                </div>
                            </div>
                            :
                            <div className="row m-0">
                                {galeria.payload.map((item, key) => (
                                    <div key={key} className="col-6 col-md-4 col-lg-3 p-0 overlay-dark overlay-zoom img-container">
                                         <ButtonBase>
                                              <img src={`${process.env.REACT_APP_SGE_URL}/_uploads/galeria/fotos/${item.url}`} alt={item.legenda} />
                                         </ButtonBase> 
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </SRLWrapper>
        </SimpleReactLightbox>
    )
}