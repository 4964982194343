import EventOne from "../../features/events/event-one";
import EventTwo from "../../features/events/event-two";

export default function EventGroupOne(props) {
    const { eventos } = props;

    return (
        <div className="event-group grid">
            {eventos.payload.map((item, key) => {
                if (key === 0) {
                    return (
                        <div key={key} className="height-x2 grid-item">
                            <EventOne evento={item} loading={eventos.loading} />
                        </div>
                    )
                } else {
                    return (
                        <div key={key} className="height-x1 grid-item">
                            <EventTwo evento={item} loading={eventos.loading} number={10}/>
                        </div>
                    )
                }
            })}
        </div>
    )
}