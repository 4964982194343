import { IconButton } from '@mui/material'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

function sharepage({ title, text, url }) {
  if (navigator.share) {
    navigator.share({
      title,
      text,
      url,
    })
  }
}

function ShareButtons({ title, text, url }) {
  const pageLink = `https://bolichebrasil.com.br/${url}`

  return (
    <div className="flex">
      <IconButton size='large' style={{ color: 'inherit'//"#25D366"
     }} component={WhatsappShareButton} url={pageLink} resetButtonStyle={false} title={title}>
        <i class="fa-brands fa-whatsapp"></i>
      </IconButton>
      <IconButton size='large' style={{ color: 'inherit'//"#1DA1F2"
     }} component={TwitterShareButton} url={pageLink} resetButtonStyle={false} title={title}>
        <i class="fa-brands fa-twitter"></i>
      </IconButton>
      <IconButton size='large' style={{ color: 'inherit'//"#4267B2"
     }} component={FacebookShareButton} url={pageLink} resetButtonStyle={false} title={title}>
        <i class="fa-brands fa-facebook"></i>
      </IconButton>
      <IconButton size='large' style={{ color: 'inherit'//"#898F9C"
     }} onClick={() => sharepage({ url: pageLink, text, title })}>
        <i class="fa-solid fa-share-nodes"></i>
      </IconButton>
    </div>
  )
}

export default ShareButtons
