export const tipoDocumentos = [
    {
        id: 1,
        icon: "fa fa-file-alt",
        descricao: 'Atas'
    },
    {
        id: 2,
        icon: "fa fa-folder",
        descricao: 'Atas de Eleições'
    },
    {
        id: 3,
        icon: "fa fa-thumbs-up",
        descricao: 'Comunicados'
    },
    {
        id: 4,
        icon: "fa fa-file-alt",
        descricao: 'Editais de Convocação'
    },
    {
        id: 5,
        icon: "fa fa-folder",
        descricao: 'Estatuto'
    },
    {
        id: 6,
        icon: "fa fa-thumbs-up",
        descricao: 'Prestação de Contas'
    },
    {
        id: 7,
        icon: "fa fa-file-alt", 
        descricao: 'Regimento Interno'
    },
    {
        id: 8,
        icon: "fa fa-folder",
        descricao: 'Regras do Esporte'
    },
]