import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { portalApi } from "../../../api";
import Breadcrumbs from "../../common/Breadcrumbs";

import PostGroupOne from "../../partials/post-group/post-group-one";
import temporadas from "../../../assets/data/temporadas";
import { Alert, Button } from "@mui/material";
import PostHorizontal from "../../features/posts/post-horizontal";

export default function BolicheMidia() {
  //notícias
  const [noticias, setNoticias] = useState({
    loading: true,
    error: false,
    payload: {
      noticia1: {},
      noticia2: {},
      noticia3: {},
      noticia4: {},
    },
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [inputText, setInputText] = useState("");
  const [temporada, setTemporada] = useState("");
  // const [page, setPage] = useState(1);

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: {
        noticia1: {},
        noticia2: {},
        noticia3: {},
        noticia4: {},
        noticias: [],
      },
    });
    setMaisNoticias({
      loading: false,
      error: false,
      hasMore: true,
      payload: [],
    });
    let params = {
      flag_del: 0,
      flag_publicado: 1,
      id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
      id_coluna: 3,
      expand: "idEsporte,user,idPessoa",
      sort: "-created_at",
      lessField: "created_at",
      lessValue: new Date().getTime().toString().substr(0, 10),
      pageSize: 8,
      // page: page,
    };

    if (temporada) {
      params = {
        ...params,
        temporada: temporada,
      };
    }
    if (searchTerm) {
      params = {
        ...params,
        arrayLike: `titulo:${searchTerm},corpo:${searchTerm}`,
      };
    }

    async function getNoticias() {
      await portalApi
        .get("noticia", {
          params: params,
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticias({
              loading: false,
              error: true,
              payload: {
                noticia1: {},
                noticia2: {},
                noticia3: {},
                noticia4: {},
                noticias: [],
              },
            });
          } else {
            setNoticias({
              loading: false,
              error: false,
              payload: {
                noticia1: response.data.items[0] ? response.data.items[0] : {},
                noticia2: response.data.items[1] ? response.data.items[1] : {},
                noticia3: response.data.items[2] ? response.data.items[2] : {},
                noticia4: response.data.items[3] ? response.data.items[3] : {},
                noticias: response.data.items[4]
                  ? response.data.items.slice(4)
                  : [],
              },
            });
          }
        })
        .catch((err) => {
          setNoticias({
            loading: false,
            error: true,
            payload: {
              noticia1: {},
              noticia2: {},
              noticia3: {},
              noticia4: {},
              noticias: [],
            },
          });
          console.log(err);
        });
    }
    getNoticias();
  }, [temporada, searchTerm]);

  const [maisNoticias, setMaisNoticias] = useState({
    loading: true,
    error: false,
    hasMore: true,
    payload: [],
  });

  const [pageNoticias, setPageNoticias] = useState(2);

  const fetchMoreNoticias = () => {
    setPageNoticias(pageNoticias + 1);
    if (pageNoticias > 1) {
      let params = {
        flag_del: 0,
        flag_publicado: 1,
        id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
        id_coluna: 3,
        expand: "idEsporte,user,idPessoa",
        sort: "-created_at",
        lessField: "created_at",
        lessValue: new Date().getTime().toString().substr(0, 10),
        pageSize: 8,
        page: pageNoticias,
      };
      if (temporada) {
        params = {
          ...params,
          temporada: temporada,
        };
      }
      if (searchTerm) {
        params = {
          ...params,
          arrayLike: `titulo:${searchTerm},corpo:${searchTerm}`,
        };
      }

      async function getMaisNoticias() {
        await portalApi
          .get("noticia", {
            params: params,
          })
          .then((response) => {
            let hasMore = true;
            if (
              response.data._meta.pageCount === response.data._meta.currentPage
            ) {
              hasMore = false;
            }
            setMaisNoticias({
              loading: false,
              error: false,
              hasMore,
              payload: [...maisNoticias.payload, ...response.data.items],
            });
          })
          .catch((err) => {
            setMaisNoticias({
              loading: false,
              error: true,
              hasMore: false,
              payload: [],
            });
            console.log(err);
          });
      }
      getMaisNoticias();
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchTerm(e.target.value);
    }
  };

  const handleChangeTemporada = (e) => {
    if (e.target.value) {
      setTemporada(e.target.value);
    }
  };

  const breadcrumb = [{ label: "Boliche na Mídia", url: "" }];

  return (
    <>
      <Helmet>
        <title>Boliche na Mídia - {process.env.REACT_APP_SITETITLE}</title>
      </Helmet>
      <Breadcrumbs paths={breadcrumb} />

      <main className="main noticias">
        <div className="ranking-list ranking-list-one">
          <div className="container">
            <div className="list-header">
              <div className="section-title mb-0">
                <h2 className="text-secondary-light">
                  Boliche <br />
                  na Mídia
                </h2>
              </div>

              <div className="input-box ml-auto">
                <label className="text-white">Pesquisar:</label>
                <input
                  className="form-control"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                />
              </div>
              <div className="select-box ml-3">
                <label className="text-white">Temporada:</label>
                <select
                  className="form-control"
                  onChange={handleChangeTemporada}
                >
                  {temporadas.map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mb-5">
          {noticias.error ? (
            <Alert severity="error" sx={{ fontSize: 16, marginTop: "5rem" }}>
              Nenhuma notícia encontrada para os filtros selecionados.
            </Alert>
          ) : (
            <React.Fragment>
              <PostGroupOne noticias={noticias} />
              {noticias.payload.noticias ? (
                <div className="row mt-5">
                  {noticias.payload.noticias.map((item, key) => (
                    <div className="col-md-6 mb-1">
                      <PostHorizontal
                        key={key}
                        noticia={item}
                        loading={noticias.loading}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </div>

        <div className="container pb-5">
          {maisNoticias.error ? (
            ""
          ) : (
            <div className="row mb-5">
              {maisNoticias.payload.map((item, key) => (
                <div className="col-md-6 mb-1">
                  <PostHorizontal
                    key={key}
                    noticia={item}
                    loading={maisNoticias.loading}
                  />
                </div>
              ))}
            </div>
          )}
          {maisNoticias.hasMore ? (
            <div className="text-center">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => fetchMoreNoticias()}
                className="btn-mais-noticias"
              >
                Mais notícias
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </main>
    </>
  );
}
