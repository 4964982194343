import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import { convertFullDateMonthString } from "../../../util/convertDate";
import { Skeleton } from "@mui/material";

export default function PostTwo(props) {
  const { noticia, loading } = props;

  const imgPostFallback = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/logos/boliche-brasil.webp`;
    e.target.style =
      "filter: grayscale(1); object-fit: contain; opacity:0.8;width: 100%;";
    e.target.parentNode.style = "background:#fff";
  };

  if (noticia.id) {
    return (
      <article className="post post-two">
        <Link
          to={
            loading
              ? "/"
              : `/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`
          }
        >
          <figure className="post-media">
            {loading ? (
              <Skeleton variant="rectangular" height={380} width={300} />
            ) : (
              <img
                src={`${process.env.REACT_APP_SGE_URL}/${noticia.urlFotoCapa}`}
                alt="post-media"
                onError={(e) => imgPostFallback(e)}
              />
            )}
            <div className="btn-wrapper x-center">
              <span className="btn btn-link text-uppercase">
                {loading ? "" : noticia.idEsporte.descricao}
              </span>
            </div>
          </figure>
        </Link>

        <div className="post-detail">
          <Link
            to={
              loading
                ? "/"
                : `/noticias/leitura/${noticia.id}/${createSlug(
                    noticia.titulo
                  )}`
            }
            className="btn btn-link text-uppercase"
          >
            {loading ? "" : noticia.idEsporte.descricao}
          </Link>

          <h4 className="post-date">
            {loading
              ? ""
              : convertFullDateMonthString(noticia.created_at * 1000)
                  .replaceAll(".", "")
                  .toUpperCase()}
          </h4>
          <Link
            to={
              loading
                ? "/"
                : `/noticias/leitura/${noticia.id}/${createSlug(
                    noticia.titulo
                  )}`
            }
          >
            <h2 className="post-title">{loading ? "" : noticia.titulo}</h2>
          </Link>
        </div>
      </article>
    );
  }
  return "";
}
