import { Alert, Card, CardActionArea, CardContent, Container, Grid, Grow, Pagination, PaginationItem, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { convertSecondsDate } from '../../../util/convertDate';
import { createSlug } from '../../../util/createSlug';
import { portalApi } from '../../../api';
import Breadcrumbs from '../../common/Breadcrumbs';

export default function Galerias(params) {

    const page = params.page;
    const esporte = params.esporte;
    const sectionTop = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [galeria, setGaleria] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [idEsporte, setIDEsporte] = useState(0);


    useEffect(() => {
        switch (esporte) {
            case 'geral':
                setIDEsporte(0)
                break;
            case 'taekwondo':
                setIDEsporte(1)
                break;
            case 'parataekowndo':
                setIDEsporte(2)
                break;
            case 'poomsae':
                setIDEsporte(3)
                break;
            default:
                setIDEsporte(0)
                break;
        }
    }, [esporte])

    useEffect(() => {
        setLoading(true)
        setError(false)

        async function getGaleria() {
            await portalApi.get('galeria', {
                params: {
                    flag_del: 0,
                    id_estabelecimento: 1,
                    flg_publicado: 1,
                    id_esporte: idEsporte > 0 ? idEsporte : '',
                    sort: '-data',
                    expand: 'galeriaFotoDestaque',
                    page: page,
                    pageSize: 21,
                },
            }).then((response) => {
                setPageCount(response.data._meta.pageCount)
                setGaleria(response.data.items)
                // console.log(response.data.items)
                if (response.data._meta.totalCount < 1) {
                    setError(true)

                }
                setLoading(false)

            })
        }
        getGaleria()
    }, [page, idEsporte])

    const breadcrumb = [ 
        { label: 'Galerias', url: "" },
      ]

    return (
        <> 
        <Breadcrumbs paths={breadcrumb} />
            <div ref={sectionTop}></div>
            {/* <div className="filter-galeria">
                <Container className="filter-container">
                    <h2 className="title-filtrar">Filtrar:</h2>
                    <Grid container alignItems="center" justifyContent="center" className="filter-buttons">
                        <ButtonBase className="btn-filtros" component={Link} to={"galerias"} color="inherit">TODAS</ButtonBase>
                        <Divider className="divider" orientation="vertical" flexItem />
                        <ButtonBase className="btn-filtros" component={Link} to={"galerias?esporte=geral"} color="inherit">GERAL</ButtonBase>
                        <Divider className="divider" orientation="vertical" flexItem />
                        <ButtonBase className="btn-filtros" component={Link} to={"galerias?esporte=taekwondo"} color="inherit">LUTA</ButtonBase>
                        <Divider className="divider" orientation="vertical" flexItem />
                        <ButtonBase className="btn-filtros" component={Link} to={"galerias?esporte=parataekowndo"} color="inherit">PARATEKWONDO</ButtonBase>
                        <Divider className="divider" orientation="vertical" flexItem />
                        <ButtonBase className="btn-filtros" disabled color="inherit">POOMSAE</ButtonBase>
                        <Divider className="divider" orientation="vertical" flexItem />
                        <ButtonBase className="btn-filtros" disabled color="inherit">MARCIAL</ButtonBase>

                    </Grid>
                </Container>
            </div> */}
            <div className="main transparencia" >

                <div className="ranking-list ranking-list-one transparencia-header">
                    <div className="container">
                        <div className="list-header">
                            <div className="section-title mb-0">
                                <h2 className="text-white">Galerias</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Container className="grid-galeria" style={{ position: 'relative', zIndex: 10 }}>
                {error ?
                    <Alert severity="error" sx={{ fontSize: 16, marginTop: '8rem' }}>Nenhuma galeria encontrada para os filtros selecionados.</Alert>
                    :
                    <>
                        <Grid container spacing={3}>
                            {(loading ? Array.from(new Array(6)) : galeria).map((item, key) => (
                                <Grid item key={key} xs={12} sm={12} md={4}>
                                    <Grow in={true} timeout={600}>
                                        {loading ?
                                            <Skeleton height={250}/> :
                                            <Card square elevation={0} className="card-galeria">
                                                <CardActionArea component={Link} to={`/galeria/${item.id}/${createSlug(item.descricao)}`}>
                                                    <div className="img-container">
                                                        <img src={`https://sge.cbtkd.com.br/_uploads/galeria/fotos/${item.galeriaFotoDestaque[0] ? item.galeriaFotoDestaque[0].url : ''}`}
                                                            alt={item.descricao} />
                                                    </div>
                                                </CardActionArea>
                                                <CardContent className="card-content">
                                                    {/* {item.id_esporte} */}
                                                    <Typography className="data"> {convertSecondsDate(item.data)}</Typography>
                                                    <Link to={`/galeria/${item.id}/${createSlug(item.descricao)}`} className="titulo-galeria"> {item.descricao}</Link>
                                                </CardContent>
                                            </Card>
                                        }
                                    </Grow>
                                </Grid>
                            ))}
                        </Grid>
                        {pageCount > 1 ?
                            <div className="pagination-wrapper">
                                <div className="container-fluid">
                                    <Route>
                                        {({ location }) => {
                                            const query = new URLSearchParams(location.search);
                                            const page = parseInt(query.get('p') || '1', 10);

                                            return (
                                                <Pagination
                                                    className="pagination"
                                                    page={page}
                                                    count={pageCount}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            component={Link}
                                                            to={`/galeria${item.page === 1 ? '' : `?p=${item.page}`}`}
                                                            {...item}
                                                        />
                                                    )}
                                                />
                                            );
                                        }}
                                    </Route>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </Container>

        </>
    )
}
