import PostOne from '../../features/posts/post-one';
import PostTwo from '../../features/posts/post-two';
import PostThree from '../../features/posts/post-three';

export default function PostGroupOne(props) {
    const { noticias } = props;

    return (
        <div className="post-group grid">
            <div className="width-x2 height-x3 grid-item">
                <PostOne noticia={noticias.payload.noticia1} loading={noticias.loading} />
            </div>
            <div className="width-x2 height-x2 grid-item">
                <PostTwo noticia={noticias.payload.noticia2} loading={noticias.loading} />
            </div>
            <div className="width-x1 height-x1 grid-item">
                <PostThree noticia={noticias.payload.noticia3} loading={noticias.loading} />
            </div>
            <div className="width-x1 height-x1 grid-item">
                <PostThree noticia={noticias.payload.noticia4} loading={noticias.loading} />
            </div>
        </div>
    )
}