import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { convertDate, convertDateYear } from "../../util/convertDate";
import { ConfedApi, gestaoApi } from "../../api";
import { Avatar, Button, Skeleton } from "@mui/material";
import Breadcrumbs from "../common/Breadcrumbs";
import { Helmet } from "react-helmet";
import { createSlug } from "../../util/createSlug";
import _ from "lodash";

export default function Aleta() {
  const [atleta, setAtleta] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const { id } = useParams();

  useEffect(() => {
    setAtleta({
      loading: true,
      error: false,
      payload: [],
    });

    async function getAtleta() {
      await gestaoApi
        .get("atleta", {
          params: {
            id: id,
            expand: "estabelecimento",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setAtleta({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setAtleta({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        });
    }
    getAtleta();
  }, [id]);

  const [eventoAtleta, setEventoAtleta] = useState({
    loading: true,
    error: false,
    totalCount: 0,
    hasMore: false,
    payload: [],
  });
  useEffect(() => {
    setEventoAtleta({
      loading: true,
      error: false,
      hasMore: false,
      payload: [],
    });

    async function getEventoAtleta() {
      await ConfedApi.get("evento-atleta", {
        params: {
          id_atleta: id,
          expand: "evento,eventoAtletaLinhas,totalAtletas",
          sort: "-evento.data_inicio",
          pageSize: 10,
        },
      }).then((response) => {
        let hasMore = true;
        if (response.data._meta.pageCount === response.data._meta.currentPage) {
          hasMore = false;
        }
        if (response.data._meta.totalCount === 0) {
          setEventoAtleta({
            loading: false,
            error: true,
            hasMore: hasMore,
            payload: [],
          });
        } else {
          setEventoAtleta({
            loading: false,
            error: false,
            hasMore: hasMore,
            payload: response.data.items,
          });
        }
      });
    }
    getEventoAtleta();
  }, [id]);

  const [page, setPage] = useState(2);

  const fetchMoreData = () => {
    async function getEventoAtleta() {
      await ConfedApi.get("evento-atleta", {
        params: {
          id_atleta: id,
          expand: "evento,eventoAtletaLinhas,totalAtletas",
          sort: "-evento.data_inicio",
          pageSize: 10,
          page: page,
        },
      }).then((response) => {
        let hasMore = true;
        if (response.data._meta.pageCount === response.data._meta.currentPage) {
          hasMore = false;
        }
        if (response.data._meta.totalCount > 0) {
          setEventoAtleta({
            loading: false,
            error: false,
            hasMore: hasMore,
            payload: [...eventoAtleta.payload, ...response.data.items],
          });
        }
      });
    }

    getEventoAtleta();
    setPage(page + 1);
  };

  const breadcrumb = [
    { label: "ranking", url: "/ranking" },
    {
      label: atleta.payload.nome_completo ? atleta.payload.nome_completo : "",
      url: "",
    },
  ];

  const calculaMedia = (item) => {
    if (item.length) {
      let soma = item.reduce(
        (prev, curr) => Number(prev) + Number(curr.valor),
        0
      );
      return (soma / item.length).toFixed(2);
    } else {
      return "--";
    }
  };

  const calculaLinhas = (item) => {
    if (item.length) {
      let soma = item.reduce(
        (prev, curr) => Number(prev) + Number(curr.valor),
        0
      );
      return soma;
    }
    return "--";
  };
  const [maioresLinhas, setMaioresLinhas] = useState([]);

  useEffect(() => {
    if (!eventoAtleta.error || !eventoAtleta.loading) {
      let linhas = eventoAtleta.payload.map((item) => {
        let maiorLinha = _.orderBy(
          item.eventoAtletaLinhas,
          ["valor"],
          ["desc"]
        );
        return maiorLinha[0];
      });

      setMaioresLinhas(linhas);
    }
  }, [eventoAtleta]);

  return (
    <>
      {atleta.loading || atleta.error ? (
        ""
      ) : (
        <Helmet>
          <title>
            {atleta.payload.nome_completo} - {process.env.REACT_APP_SITETITLE}
          </title>
        </Helmet>
      )}
      <Breadcrumbs paths={breadcrumb} />
      <main className="main aleta">
        <div className="section-title text-primary bg-primary-light">
          <div className="container">
            <h2>Atleta</h2>
          </div>
        </div>

        <div className="container-fluid" style={{ marginTop: "-23rem" }}>
          <div className="right-circle">
            <div className="card">
              <div className="main-atleta">
                {atleta.loading || atleta.error ? (
                  ""
                ) : (
                  <div className="aleta-info align-items-center">
                    <Avatar
                      className="aleta-media"
                      variant="circle"
                      src={`${process.env.REACT_APP_SGE_URL}/_uploads/fotoAtleta/${atleta.payload.foto}`}
                      alt={
                        atleta.payload.foto ? atleta.payload.nome_completo : ""
                      }
                    />

                    <div className="aleta-bio text-white">
                      <h2>{atleta.payload.nome_completo}</h2>

                      <div className="d-flex">
                        <div className="aleta-item">
                          <h5>IDADE</h5>
                          <h4>
                            {convertDateYear(atleta.payload.data_nascimento)}
                          </h4>
                        </div>

                        <div className="aleta-item">
                          <h5>UF</h5>
                          <h4>
                            {atleta.payload
                              ? atleta.payload.estabelecimento.uf
                              : ""}
                          </h4>
                        </div>

                        {/* <div className="aleta-item">
													<h5>RANKING</h5>
													<h4>--</h4>
												</div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {eventoAtleta.loading || eventoAtleta.error
            ? Array.from(new Array(3)).map((item, key) => (
                <div className="board" key={key}>
                  <div className="board-top">
                    <Skeleton
                      variant="text"
                      style={{ background: "#ffffff70" }}
                    />
                  </div>

                  <div className="board-middle">
                    <h5>
                      <Skeleton
                        variant="text"
                        style={{ background: "#ffffff70" }}
                      />
                    </h5>
                    <h5>
                      <Skeleton
                        variant="text"
                        style={{ background: "#ffffff70" }}
                      />
                    </h5>
                  </div>

                  <div className="tags">
                    <div className="tag-group">
                      {Array.from(new Array(10)).map((item, key) => (
                        <Link to="#" className="tag">
                          LINHA {key + 1}
                          <br />
                          <Skeleton
                            variant="text"
                            style={{ background: "#ffffff70" }}
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            : eventoAtleta.payload.map((item, key) => (
                <div className="board" key={key}>
                  <div className="board-top">
                    <Link
                      to={`/evento/${item.evento.id}/${createSlug(
                        item.evento.descricao
                      )}`}
                    >
                      {item.evento.descricao}
                    </Link>
                  </div>

                  <div className="board-middle">
                    <h5>
                      Data: {convertDate(item.evento.data_inicio)} / Atletas:{" "}
                      {item.totalAtletas}{" "}
                      {item.colocacao_categoria
                        ? `/ Posição All-Events: ${item.colocacao_categoria}º lugar`
                        : ""}
                    </h5>
                    {item.eventoAtletaLinhas.length ? (
                      <h5>
                        Linhas Jogadas: {item.eventoAtletaLinhas.length} / Total
                        de Pinos: {calculaLinhas(item.eventoAtletaLinhas)} /
                        Média: {calculaMedia(item.eventoAtletaLinhas)}
                      </h5>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="tags">
                    <div className="tag-group">
                      {item.eventoAtletaLinhas.map((item, index) => {
                        if (maioresLinhas[key]) {
                          return (
                            <span
                              key={key}
                              className={`tag ${
                                maioresLinhas[key].id === item.id
                                  ? "active"
                                  : ""
                              }`}
                            >
                              LINHA {index + 1}
                              <br />
                              {item.valor}
                            </span>
                          );
                        } else {
                          return <React.Fragment key={key}></React.Fragment>;
                        }
                      })}
                    </div>
                  </div>
                </div>
              ))}
          {eventoAtleta.hasMore ? (
            <div className="my-5 text-center">
              <Button
                variant="outlined"
                className="btn-load-content"
                onClick={() => fetchMoreData()}
              >
                Carregar mais
              </Button>
            </div>
          ) : null}
        </div>
      </main>
    </>
  );
}
