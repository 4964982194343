import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { createSlug } from "../../../util/createSlug";
import { Avatar, Button, Skeleton } from "@mui/material";
import EstadoFlag from "../../../assets/img/flags/EstadosFlag";
import SwipeableViews from "react-swipeable-views";
import { convertDate } from "../../../util/convertDate";

export default function PlayerOne(props) {
  const { atleta, loading } = props;

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [ptsPorEvento, setPtsPorEvento] = useState([]);

  useEffect(() => {
    if (atleta) {
      let ptsEventos = atleta.evento_pontos.split(",");

      let ptsmap = ptsEventos.map((item, key) => {
        let itemSplit = item.split(":");
        let filterEvento = _.filter(
          JSON.parse(atleta.eventosInfo),
          function (o) {
            return o.id === Number(itemSplit[0]);
          }
        );

        return {
          id_evento: itemSplit[0],
          pts: itemSplit[1],
          ...filterEvento[0],
        };
      });
      let ordered = _.orderBy(ptsmap, ["data_inicio"], ["desc"]);
      let chunk = _.chunk(ordered, 3);
      setPtsPorEvento(chunk);
    }
  }, [atleta]);

  const [posColor, setPosColor] = useState("");
  //test color
  useEffect(() => {
    if (!loading) {
      switch (Number(atleta.colocacao)) {
        case 1:
          setPosColor("green");
          break;
        case 2:
          setPosColor("light");
          break;
        case 3:
          setPosColor("secondary");
          break;
        default:
          setPosColor("");
          break;
      }
    }
  }, [atleta, loading]);

  return (
    <div className="player player-one">
      <div className="player-info d-flex">
        <div className={`player-number bg-${posColor}`}>
          <h2>{loading ? "" : atleta.colocacao}</h2>
        </div>

        <div className="player-avatar">
          {loading ? (
            <Skeleton variant="circular" />
          ) : (
            <Avatar
              src={`${process.env.REACT_APP_SGE_URL}_uploads/fotoAtleta/${atleta.foto}`}
              alt={atleta.foto ? atleta.nome_completo : ""}
            />
          )}
        </div>

        <div className="player-name text-white">
          {loading ? (
            <Skeleton variant="text" width={120} />
          ) : (
            <Link to={`/atleta/${atleta.id}/${createSlug(atleta.nome_evento)}`}>
              {atleta.nome_evento}
            </Link>
          )}
        </div>

        <div className="player-nation">
          {loading ? (
            <Skeleton variant="rectangular" width={60} height={40} />
          ) : (
            <EstadoFlag estado={atleta ? atleta.uf : ""} />
          )}
        </div>
      </div>

      <div className="player-record d-flex text-white align-items-center">
        {loading ? (
          <div className="w-75 d-flex justify-content-around">
            <Skeleton variant="text" width={50} />
            <Skeleton variant="text" width={50} />
            <Skeleton variant="text" width={50} />
          </div>
        ) : (
          <React.Fragment>
            <Button
              sx={{
                minWidth: "unset",
                height: 30,
                width: 30,
                color: "#fff",
                fontSize: "1.2rem",
              }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <i className="fa-solid fa-angle-left"></i>
            </Button>
            <SwipeableViews
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              className="w-100"
            >
              {ptsPorEvento.map((item, key) => (
                <div
                  key={key}
                  className="d-flex align-items-center"
                  style={{ height: 35 }}
                >
                  {item.map((item, key) => (
                    <div
                      className={`player-score text-center ${
                        key === 2 ? "border-0" : ""
                      }`}
                    >
                      <Link
                        to={`/evento/${item.id_evento}/${createSlug(
                          item.descricao
                        )}`}
                      >
                        <h3>{item.pts}</h3>
                        <h6>{convertDate(item.data_inicio)}</h6>
                      </Link>
                    </div>
                  ))}
                </div>
              ))}
            </SwipeableViews>
            <Button
              sx={{
                minWidth: "unset",
                height: 30,
                width: 30,
                color: "#fff",
                fontSize: "1.2rem",
              }}
              onClick={handleNext}
              disabled={activeStep === ptsPorEvento.length - 1}
            >
              <i className="fa-solid fa-angle-right"></i>
            </Button>
          </React.Fragment>
        )}

        <div className="total-score text-secondary">
          <h3> {loading ? <Skeleton variant="text" /> : atleta.pontos}</h3>
        </div>
      </div>

      <hr className="text-white mt-5 mb-5" />
    </div>
  );
}
