// import { useEffect } from 'react';
import {
  Alert,
  Avatar,
  Button,
  Collapse,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EstadoFlag from "../../../../assets/img/flags/EstadosFlag";
import {
  //   AddCircleSVG,
  ArrowCircleSVG,
  //   RemoveCircleSVG,
} from "../../../../assets/icons";
import { Box } from "@mui/system";
import _ from "lodash";
import {
  calculaDifColocacao,
  calculaDifPrimeiro,
  calculaEmpates,
  calculaTotalPts,
  handleFlags,
  nomeAtletas,
} from "./functions/TabelaResultados";
// import flag from '../../../../assets/img/flag.png'

function TabelaResultados(props) {
  const {
    eventoAtletaTabela,
    categoria,
    handleChangeDia,
    diaEvento,
    idCategoria,
    diasEvento,
  } = props;

  const [tabelaResultados, setTabelaResultados] = useState([]);

  useEffect(() => {
    if (!eventoAtletaTabela.error && !eventoAtletaTabela.loading) {
      const tabela = eventoAtletaTabela.payload.tabela;
      if (tabela.length) {
        const addMedia = tabela.map((item, key) => ({
          ...item,
          colocacao: key + 1,
          media: calculaMedia(item),
        }));
        setTabelaResultados(calculaEmpates(addMedia));
      }
    }
  }, [eventoAtletaTabela]);

  const [openLinha, setOpenLinha] = useState(false);

  const handleClickLinha = (target, index) => {
    setOpenLinha({ [index]: !openLinha[index] });
  };

  const handleOpenRow = (target, index) => {
    handleExpandTable(target, index);
    handleClickLinha(target, index);
  };

  const [openTable, setOpenTable] = useState(false);
  const handleExpandTable = (target, index) => {
    setOpenTable({ [index]: !openTable[index] });
  };

  const calculaMedia = (item) => {
    let linhas = Number(item.l);
    let ptsSerie = calculaTotalPts(item);
    return (ptsSerie / linhas).toFixed(2);
  };

  const [expandTodos, setEspandTodos] = useState(false);

  const createLinhas = (linhas) => {
    let jsonLinhas = JSON.parse(linhas.linhas);
    let orderLinhas = _.orderBy(jsonLinhas, ["n"], ["asc"]);

    if (orderLinhas) {
      return (
        <React.Fragment>
          <TableHead>
            <TableRow>
              {!linhas.anterior ? "" : <TableCell>Anteriores</TableCell>}
              {orderLinhas.map((item, key) => (
                <TableCell sx={{ width: 80 }} key={key}>
                  L{item.n}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {!linhas.anterior ? "" : <TableCell>{linhas.anterior}</TableCell>}
              {orderLinhas.map((item, key) => (
                <TableCell key={key}>
                  <span
                    style={
                      item.r > 0
                        ? { border: "2px solid white", padding: "3px 6px" }
                        : {}
                    }
                  >
                    {item.v + item.r}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  return (
    <div className="pb-5">
      <div className="board board-table">
        <div className="row align-items-end board-header">
          <div className="col-md-12">
            <h2 className="board-title">
              {categoria.divisao &&
                `${categoria.divisao}º DIVISÃO - ${categoria.descricao}`}
              {/* <span>Atletas Inscritos{`(${eventoAtleta.totalCount})`}</span> */}
            </h2>
          </div>
        </div>
        <div className="board-body board-body-table">
          {categoria.dias ? (
            <Button variant="outlined" className="active">
              Dia {categoria.dias}
            </Button>
          ) : (
            <React.Fragment>
              {diasEvento ? (
                <div className="d-flex">
                  {diasEvento > 1
                    ? Array.from(new Array(Number(diasEvento))).map(
                        (item, key) => (
                          <Button
                            key={key}
                            onClick={() => handleChangeDia(key + 1)}
                            variant="outlined"
                            className={diaEvento === key + 1 ? "active" : ""}
                          >
                            Dia {key + 1}
                          </Button>
                        )
                      )
                    : ""}
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
          <div className="d-md-none">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1f4f8da6",
                color: "#fff !important",
                borderColor: "#fff !important",
              }}
              onClick={(e) => setEspandTodos(!expandTodos)}
            >
              {expandTodos ? "Ocultar" : "Expandir"} Tabela
            </Button>
          </div>

          <Table className="table my-5 text-white table-atletas">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 350 }}></TableCell>
                <TableCell sx={{ width: 150 }}>
                  <span className="d-none d-md-block">Linhas</span>
                  <span className="d-md-none">L</span>
                </TableCell>
                <TableCell className="col-hide-md" sx={{ width: 80 }}>
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title="Linhas Jogadas"
                  >
                    <Box>L</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className="col-hide-md" sx={{ width: 80 }}>
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title="Pontos da Série"
                  >
                    <Box>S</Box>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: 80 }}>
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title="Total de Pontos"
                  >
                    <Box>T</Box>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: 80 }}>
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title="Média"
                  >
                    <Box>M</Box>
                  </Tooltip>
                </TableCell>

                <TableCell
                  className={
                    (Boolean(categoria.mapa) || categoria.baker === 1) &&
                    "col-hide-md col-hide-lg"
                  }
                  sx={{ width: 80, minWidth: 60 }}
                >
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title="Diferença para o 1º Colocado"
                  >
                    <Box>DIF. 1º</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className="col-hide-md col-hide-lg"
                  sx={{ width: 80 }}
                >
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title={`Diferença para o ${
                      categoria.pos_dif ? categoria.pos_dif : ""
                    } º Colocado`}
                  >
                    <Box>
                      DIF. {categoria.pos_dif ? categoria.pos_dif : ""}º
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className="col-hide-md col-hide-lg"
                  sx={{ width: 80 }}
                >
                  <Tooltip
                    describeChild
                    placement="top-end"
                    arrow
                    title="Maior Linha"
                  >
                    <Box>ML</Box>
                  </Tooltip>
                </TableCell>

                {categoria.baker === 1 ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <TableCell
                    className="col-hide-md col-hide-lg"
                    sx={{ width: 80 }}
                  >
                    <Tooltip
                      describeChild
                      placement="top-end"
                      arrow
                      title="Maior Série"
                    >
                      <Box>MS</Box>
                    </Tooltip>
                  </TableCell>
                )}
                {Boolean(categoria.mapa) || categoria.baker === 1 ? (
                  <TableCell sx={{ width: 80 }}>
                    <Tooltip
                      describeChild
                      placement="top-end"
                      arrow
                      title="Pontos"
                    >
                      <Box>PTS</Box>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </TableRow>
            </TableHead>
            {eventoAtletaTabela.error ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11}>
                    {!idCategoria ? (
                      "Selecione uma categoria"
                    ) : (
                      <Alert severity="error" sx={{ fontSize: "1.4rem" }}>
                        Não foi possível carregar esta tabela ou ainda não há
                        registros encontrados.
                      </Alert>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {eventoAtletaTabela.loading ? (
                  Array.from(new Array(5)).map((item, key) => (
                    <TableRow key={key}>
                      <TableCell colSpan={11}>
                        <Skeleton
                          style={{ background: "rgba(255,255,255,0.4)" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <React.Fragment>
                    <TableRow>
                      <TableCell style={{ border: "none" }}></TableCell>
                    </TableRow>
                    {tabelaResultados.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Alert severity="warning" sx={{ fontSize: "1.4rem" }}>
                            Ainda não há nenhum registro para este dia.
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                    {tabelaResultados.map((item, key) => {
                      let _posicao = categoria.pos_dif
                        ? Number(categoria.pos_dif)
                        : "";
                      const primeiro = eventoAtletaTabela.payload.tabela[0];
                      const tabela = eventoAtletaTabela.payload.tabela;

                      return (
                        <React.Fragment key={key}>
                          <TableRow
                            onClick={(e) => handleOpenRow(e.currentTarget, key)}
                            style={
                              _posicao === key + 1
                                ? { borderBottom: "2px solid #fff084" }
                                : {}
                            }
                          >
                            <TableCell className="td nome-atleta justify-content-start">
                              <div className="d-flex align-items-start text-start">
                                <b>{item.colocacao || 0}.</b>
                                <div className="mx-2">
                                  <div className="d-flex align-items-center gap-2">
                                    {
                                      'eq' in item ? null
                                      : <EstadoFlag
                                          size={{ width: 30, height: 24 }}
                                          estado={handleFlags(item)}
                                        />
                                    }
                                    {
                                      'eq' in item || item.equipe ?
                                        <Avatar
                                          variant="rounded"
                                          style={{ width: 30, height: 30 }}
                                          src={`${process.env.REACT_APP_SGE_URL}/_uploads/estabelecimento/equipe/logos/${item.equipe_logo ?? 'noLogo.png'}`}
                                        />
                                      : null
                                    }
                                    <div className="ms-2 ms-md-3">
                                      {nomeAtletas(item)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="td linhas">
                              <div>
                                <span className="d-none d-md-block">
                                  Linhas
                                  <IconButton
                                    onClick={(e) =>
                                      handleClickLinha(e.currentTarget, key)
                                    }
                                    className={`ms-2 ${
                                      openLinha[key]
                                        ? "open-linha"
                                        : "close-linha"
                                    }`}
                                    size="small"
                                  >
                                    <ArrowCircleSVG
                                      style={{ height: 20, width: 20 }}
                                    />
                                  </IconButton>
                                </span>
                                <span className="d-md-none">
                                  {item.linhas
                                    ? JSON.parse(item.linhas).length
                                    : ""}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell className="col-hide-md">
                              {item.l}
                            </TableCell>
                            <TableCell className="col-hide-md">
                              {item.pontos_serie}
                            </TableCell>
                            <TableCell>{calculaTotalPts(item)}</TableCell>
                            <TableCell>{calculaMedia(item)}</TableCell>

                            <TableCell
                              className={
                                (Boolean(categoria.mapa) ||
                                  categoria.baker === 1) &&
                                "col-hide-md col-hide-lg"
                              }
                            >
                              {calculaDifPrimeiro(item, primeiro)}
                            </TableCell>
                            <TableCell className="col-hide-md col-hide-lg">
                              {calculaDifColocacao(item, tabela, categoria)}
                            </TableCell>
                            <TableCell className="col-hide-md col-hide-lg">
                              {item.maior_linha}
                            </TableCell>
                            {categoria.baker === 1 ? (
                              <React.Fragment></React.Fragment>
                            ) : (
                              <TableCell className="col-hide-md col-hide-lg">
                                {item.maior_serie}
                              </TableCell>
                            )}
                            {Boolean(categoria.mapa) ||
                            categoria.baker === 1 ? (
                              <TableCell>{item.pontos}</TableCell>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={item.pontos ? 11 : 10}
                              className="td-detalhes"
                            >
                              <Collapse
                                className="collapse-detalhes"
                                in={openLinha[key] || expandTodos}
                              >
                                <Table className="table table-detalhes">
                                  {createLinhas(item)}
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>

                          <TableRow className="row-expand-md">
                            <TableCell
                              className="td-detalhes border-0"
                              colSpan={10}
                            >
                              <Collapse
                                className="collapse-expand"
                                in={openTable[key] || expandTodos}
                              >
                                <div className="table-expand py-3">
                                  <Table>
                                    <TableRow className="row-show-sm">
                                      <TableCell className="th">
                                        Pontos da Série
                                      </TableCell>
                                      <TableCell>{item.pontos_serie}</TableCell>
                                      <TableCell className="th">
                                        Total de Pontos
                                      </TableCell>
                                      <TableCell>
                                        {calculaTotalPts(item)}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className="row-show-sm">
                                      <TableCell className="th">
                                        Média
                                      </TableCell>
                                      <TableCell>
                                        {calculaMedia(item)}
                                      </TableCell>
                                      <TableCell className="th">
                                        Linhas
                                      </TableCell>
                                      <TableCell>{item.l}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="th">
                                        DIF. 1º
                                      </TableCell>
                                      <TableCell>
                                        {calculaDifPrimeiro(item, primeiro)}
                                      </TableCell>
                                      <TableCell className="th">
                                        DIF.{" "}
                                        {categoria.pos_dif
                                          ? categoria.pos_dif
                                          : ""}
                                        º
                                      </TableCell>
                                      <TableCell>
                                        {calculaDifColocacao(
                                          item,
                                          tabela,
                                          categoria
                                        )}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="th">
                                        Maior Linha
                                      </TableCell>
                                      <TableCell>{item.maior_linha}</TableCell>
                                      <TableCell className="th">
                                        {categoria.baker === 1
                                          ? "Pontos"
                                          : "Maior Série"}
                                      </TableCell>
                                      <TableCell>
                                        {categoria.baker === 1
                                          ? item.pontos
                                          : item.maior_serie}
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                )}
              </TableBody>
            )}
          </Table>
        </div>
        <div className="board-content" style={{ minHeight: 50 }}>
          {eventoAtletaTabela.error || eventoAtletaTabela.loading ? (
            ""
          ) : (
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="card-atleta maior-linha">
                  <div className="card-content">
                    <Avatar />
                    <div className="card-atleta-info">
                      <h4>
                        MAIOR
                        <br /> LINHA
                      </h4>
                      {eventoAtletaTabela.payload.maiorLinha[0] ? (
                        <React.Fragment>
                          <h5>
                            {nomeAtletas(
                              eventoAtletaTabela.payload.maiorLinha[0]
                            )}
                          </h5>
                          <span className="card-pts">
                            {eventoAtletaTabela.payload.maiorLinha[0].valor}
                          </span>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="card-atleta maior-serie">
                  <div className="card-content">
                    <Avatar />
                    <div className="card-atleta-info">
                      <h4>
                        MAIOR
                        <br /> SERIE
                      </h4>
                      {eventoAtletaTabela.payload.maiorSerie[0] ? (
                        <React.Fragment>
                          <h5>
                            {nomeAtletas(
                              eventoAtletaTabela.payload.maiorSerie[0]
                            )}
                          </h5>
                          <span className="card-pts">
                            {
                              eventoAtletaTabela.payload.maiorSerie[0]
                                .maior_serie
                            }
                          </span>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TabelaResultados;
