import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../util/createSlug";
import { governancaApi } from "../../api";
// import { mainMenu } from '../../assets/data/menu';
import _ from "lodash";

export default function Header() {
  const [menu, setMenu] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setMenu({
      loading: true,
      error: false,
      payload: [],
    });

    async function getMenuGovernanca() {
      await governancaApi
        .get("menu-governanca", {
          params: {
            expand: "filho",
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            isNullField: "id_pai",
            sort: "ordem",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setMenu({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            var menuSortOrdem = _.sortBy(
              response.data.items,
              "ordem",
              function (n) {
                return Math.sin(n);
              }
            );

            var menuSortOrdemFilho = menuSortOrdem.map((item, key) => {
              let filho = [];
              let netos = [];
              if (item.filho) {
                filho = _.sortBy(item.filho, "ordem", function (n) {
                  return Math.sin(n);
                });

                netos = filho.map((item) => {
                  let _order = _.sortBy(item.filho, "ordem", function (n) {
                    return Math.sin(n);
                  });
                  return {
                    ...item,
                    filho: _order,
                  };
                });

                return {
                  ...item,
                  filho: netos,
                };
              }

              return item;
            });

            setMenu({
              loading: false,
              error: false,
              payload: menuSortOrdemFilho,
            });
          }
        })
        .catch((err) => {
          setMenu({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getMenuGovernanca();
  }, []);

  const handleMenuLink = (menu) => {
    if (menu.url) {
      if (menu.url.includes('http')) {
        return <a href={menu.url} target="_blank" rel="noreferrer" className="text-white">{menu.titulo}</a>
      }
      return (
        <Link to={menu.url} className="text-white">
          {menu.titulo}
        </Link>
      )
    }
    return (
      <Link to={`/${menu.id}/${createSlug(menu.titulo)}`} className="text-white">
        {menu.titulo}
      </Link>
    )
  }

  return (
    <div className="header">
      <div className="header-top">
        <div className="container">
          <div className="header-left">
            <Link to="/" className="d-none d-sm-block">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.svg`}
                width="138"
                height="138"
                alt="logo"
                className="logo"
              ></img>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-text.svg`}
                width="442"
                height="43"
                alt="logo-text"
                className="logo-text"
              ></img>
            </Link>
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-text-vertical.svg`}
                alt="logo"
                className="logo-tablet"
              ></img>
            </Link>
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-vertical.svg`}
                width="auto"
                height="43"
                alt="logo"
                className="logo-mobile"
              ></img>
            </Link>
          </div>

          <div className="header-right align-items-center d-flex">
            <div className="social-icons d-flex">
              <a
                href="https://www.facebook.com/bolichebrasil"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <i className="fa-facebook fab"></i>
              </a>

              <a
                href="https://twitter.com/bolichebrasil"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <i className="fa-twitter fab"></i>
              </a>

              <a
                href="https://www.instagram.com/bolichebrasil/"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <i className="fa-instagram fab"></i>
              </a>

              <a
                href="https://www.youtube.com/c/BolicheBrasil"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <i className="fa-youtube fab"></i>
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_SGE_URL}site/login`}
                className="btn login btn-outline-primary"
              >
                LOGIN
              </a>
            </div>
          </div>
        </div>
      </div>

      <nav className="header-middle bg-primary navbar navbar-expand-sm navbar-dark">
        <div className="container">
          <button
            className="navbar-toggler ml-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav main-nav d-flex m-auto">
              {menu.payload.map((item, key) => {
                if (item.filho.length) {
                  if (key === 1) {
                    return (
                      <li key={key} className="nav-item dropdown">
                        <a
                          className="text-white nav-link dropdown-toggle"
                          href="!#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {item.titulo}
                        </a>
                        <div
                          className="dropdown-menu mega-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {item.filho.map((filho, key) => (
                            <div className="mega-menu-item">
                              <h4 key={key}>{filho.titulo}</h4>
                              {filho.filho ? (
                                <ul>
                                  {filho.filho.map((item, index) => (
                                    <li key={index}>
                                      <Link
                                        className="dropdown-item"
                                        to={
                                          item.url
                                            ? item.url
                                            : `/${createSlug(filho.titulo)}/${item.id
                                            }/${createSlug(item.titulo)}`
                                        }
                                      >
                                        {item.titulo}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <React.Fragment key={key}></React.Fragment>
                              )}
                            </div>
                          ))}
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li key={key} className="nav-item dropdown">
                        <a
                          className="text-white nav-link dropdown-toggle"
                          href="!#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {item.titulo}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {item.filho.map((filho, key) => (
                            <li key={key}>
                              <Link
                                className="dropdown-item"
                                to={
                                  filho.url
                                    ? filho.url
                                    : `/${createSlug(item.titulo)}/${filho.id
                                    }/${createSlug(filho.titulo)}`
                                }
                              >
                                {filho.titulo}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  }
                } else {
                  return (
                    <li key={key}>
                      {handleMenuLink(item)}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </nav>
      <div className="light-bar position-relative container-fluid"></div>
    </div>
  );
}
