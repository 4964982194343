import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { convertFullDateMonthString } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import parse from "html-react-parser";
import React from "react";

export default function PostHorizontal(props) {
  const { noticia, loading } = props;

  const imgPostFallback = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/logos/boliche-brasil.webp`;
    e.target.style =
      "filter: grayscale(1); object-fit: contain; opacity:0.8;width: 100%;";
    e.target.parentNode.style = "background:#fff";
  };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.src) {
        return <React.Fragment></React.Fragment>;
      }
    },
  };

  if (noticia.id) {
    return (
      <article className="post post-three post-sm">
        <div className="row">
          <div className="col-lg-4">
            <Link
              to={
                loading
                  ? "/"
                  : `/noticias/leitura/${noticia.id}/${createSlug(
                      noticia.titulo
                    )}`
              }
            >
              <figure className="post-media">
                {loading ? (
                  <Skeleton variant="rectangular" height={150} width={"100%"} />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_SGE_URL}/${noticia.urlFotoCapa}`}
                    alt="post-media"
                    onError={(e) => imgPostFallback(e)}
                  />
                )}

                <div className="btn-wrapper x-center">
                  <span className="btn btn-link text-uppercase">
                    {loading ? "" : noticia.idEsporte.descricao}
                  </span>
                </div>
              </figure>
            </Link>
          </div>

          <div className="col-lg-8">
            <div className="post-detail">
              <Link
                to={
                  loading
                    ? "/"
                    : `/noticias/leitura/${noticia.id}/${createSlug(
                        noticia.titulo
                      )}`
                }
              >
                <p>{loading ? "" : noticia.titulo}</p>
              </Link>
              <h4 className="post-date">
                {loading
                  ? ""
                  : convertFullDateMonthString(noticia.created_at * 1000)
                      .replaceAll(".", "")
                      .toUpperCase()}
              </h4>
              <p className="post-resumo">
                {loading
                  ? ""
                  : parse(noticia.resumo.replace(/&nbsp;/g, ""), options)}
              </p>
            </div>
          </div>
        </div>
      </article>
    );
  }
  return "";
}
