import { SvgIcon } from '@mui/material';

export function SearchSVG(props) {
    return (
        <SvgIcon width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
            <path d="M17 17.4207L13.2542 13.6748M15.2778 8.80954C15.2778 12.6142 12.1935 15.6984 8.38889 15.6984C4.58426 15.6984 1.5 12.6142 1.5 8.80954C1.5 5.00491 4.58426 1.92065 8.38889 1.92065C12.1935 1.92065 15.2778 5.00491 15.2778 8.80954Z" stroke="#256AC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    )
}

export function DownArrowSVG(props) {
    return (
        <SvgIcon width="15" height="7" viewBox="0 0 15 7" fill="none" {...props}>
            <path d="M1.75 1.85498L7.75 5.57599L13.75 1.85498" stroke="#256AC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    )
}

export function PlusCircleSVG(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" dataName="Camada 1" viewBox="0 0 25.5 25.5" {...props}>
            <path
                className="plus-vertical"
                fill="none"
                stroke="#8b1ea6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12.75 8.05L12.75 17.45"
            ></path>
            <path
                fill="none"
                stroke="#8b1ea6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.05 12.75L17.45 12.75"
            ></path>
            <circle
                cx="12.75"
                cy="12.75"
                r="11.75"
                fill="none"
                stroke="#8b1ea6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            ></circle>
        </svg>
    )
}

export function ArrowCircleSVG(props) {
    return (
        <SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
            <path xmlns="http://www.w3.org/2000/svg" d="M5.09667 8.35577L7.99445 11.2536M7.99445 11.2536L10.8922 8.35577M7.99445 11.2536L7.99445 5.45799M15.2389 8.35577C15.2389 12.3568 11.9954 15.6002 7.99445 15.6002C3.99345 15.6002 0.750002 12.3568 0.750001 8.35577C0.750001 4.35478 3.99345 1.11133 7.99445 1.11133C11.9954 1.11133 15.2389 4.35477 15.2389 8.35577Z" stroke="#34C8FF"  fill="none" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    )
}

export function AddCircleSVG(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
         <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
        </SvgIcon>
    )
}
export function RemoveCircleSVG(props) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
        </SvgIcon>
    )
}