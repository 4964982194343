import { Link } from "react-router-dom";

import EventGroupOne from "../event-group/event-group-one";
export default function ScheduleOne(props) {

    const { eventos } = props;

    return (
        <div className="schedule">
            <div className="container-fluid light-bar light-bar-green">
                <div className="schedule-header text-green">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="d-flex align-items-end">Próximos<br />eventos  <svg className="mb-1" width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.634 2.24304V7.99582M9.12847 2.24304V7.99582M1.9375 13.7486H27.825M4.81389 5.11943H24.9486C26.5372 5.11943 27.825 6.40723 27.825 7.99582V28.1305C27.825 29.7191 26.5372 31.0069 24.9486 31.0069H4.81389C3.2253 31.0069 1.9375 29.7191 1.9375 28.1305V7.99582C1.9375 6.40723 3.2253 5.11943 4.81389 5.11943Z" stroke="#278664" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </h2>

                            <div className="btn-wrapper y-center btn-out">
                                <Link to="/eventos" className="text-green">VEJA TODOS EVENTOS</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <EventGroupOne eventos={eventos} />
            </div>
        </div>
    )
}