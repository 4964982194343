import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { createSlug } from '../../../util/createSlug'

export default function PostThree(props) {
    const { noticia, loading } = props;

    const imgPostFallback = (e) => {
        e.target.src = `${process.env.PUBLIC_URL}/assets/images/logos/boliche-brasil.webp`;
        e.target.style = 'filter: grayscale(1); object-fit: contain; opacity:0.8;width: 100%;'
        e.target.parentNode.style = 'background:#fff';
    }

    if (noticia.id) {
        return (
            <article className="post post-three">
                <Link to={loading ? '/' : `/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`}>
                    <figure className="post-media">
                        {loading ?
                            <Skeleton variant="rectangular" height={190} width={300} />
                            :
                            <img src={`${process.env.REACT_APP_SGE_URL}/${noticia.urlFotoCapa}`} alt="post-media" onError={(e) => imgPostFallback(e)} />
                        }

                        <div className="btn-wrapper x-center">
                            <span className="btn btn-link text-uppercase">{loading ? '' : noticia.idEsporte.descricao}</span>
                        </div>
                    </figure>
                </Link>

                <div className="post-detail">
                    <Link to={loading ? '/' : `/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`}>
                        <p>{loading ? '' : noticia.titulo}</p>
                    </Link>
                </div>
            </article>
        )
    }
    return ""
}