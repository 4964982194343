import {
  Alert,
  Avatar,
  Box, Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConfedApi } from "../../../../api";
import { createSlug } from "../../../../util/createSlug";

function TabelaPontosRanking({ idEvento, sexo }) {
  const [eventoAtleta, setEventoAtleta] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  useEffect(() => {
    setEventoAtleta({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoAtleta() {
      await ConfedApi.get("ranking?expand=atleta", {
        params: {
          id_evento: idEvento,
          pageSize:'500',
          sexo,   
        },
      }).then((response) => {
        const resData = _.toArray(response.data.items);
        if (resData.length === 0) {
          setEventoAtleta({
            loading: false,
            error: true,
            payload: [],
          });
        } else {
          setEventoAtleta({
            loading: false,
            error: false,
            payload: resData,
          });
        }
      });
    }
    getEventoAtleta();
  }, [idEvento,sexo]);

  function renderError(){
    return ( <TableRow>
      <TableCell colSpan={4}>
        <Alert severity="error" sx={{ fontSize: "15px" }}>
          Nenhum atleta encontrado.
        </Alert>
      </TableCell>
    </TableRow>)
}
  console.log(eventoAtleta)
  return (
    <div>
      <div className="pb-5">
        <TableContainer>
          <Table size="small" className="table-template">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Pontos</TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {eventoAtleta.loading &&
                Array.from(new Array(5)).map((item, key) => (
                  <TableRow key={key}>
                    <TableCell colSpan={4}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              {eventoAtleta.error && renderError()}
              {eventoAtleta.payload.filter((el)=> el.atleta.atleta.sexo === sexo).map((item) => (
                <React.Fragment key={item.id_atleta}>
                  <TableRow>
                    <TableCell>
                      <Box className="box-atleta">
                        <Avatar src={`${process.env.REACT_APP_SGE_URL}/${item.atleta.atleta.urlFoto}`} />
                        <Link to={`/atleta/${item.id_atleta}/${createSlug(item.atleta.nome_evento)}`}>
                          <Typography variant="subtitle1">{item.atleta.nome_evento}</Typography>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell alignRight>
                      <Box className="box-atleta box-atleta-pts">                        
                        <Typography textAlign={'right'} variant="subtitle1">{item.pontos}</Typography>
                      </Box>
                    </TableCell>                                     
                  </TableRow>
                  <TableRow>                   
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default TabelaPontosRanking;
