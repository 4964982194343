import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { ConfedApi, gestaoApi, portalApi } from "../../../api";
import { formatDatePeriod } from "../../../util/formatDatePeriod";
import Breadcrumbs from "../../common/Breadcrumbs";
// import CardTwo from '../../features/card/card-two';
// import RankingListOne from '../../partials/ranking-list/ranking-list-one';

import { Tab, Tabs } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import TabPanel, { a11yProps } from "./sections/components/TabPanel";
import MapaPontos from "./sections/MapaPontos";
import Partidas from "./sections/Partidas";
import TabelaInscritos from "./sections/TabelaInscritos";
import TabelaPontosRanking from "./sections/TabelaPontosRanking";
import TabelaResultados from "./sections/TabelaResultados";
import NoticiasRelacionadas from "./sections/noticias-relacionadas";

export default function BolicheEvento() {
  const { idEvento } = useParams();

  //get evento
  const [evento, setEvento] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [eventoCategoria, setEventoCategoria] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [idCategoria, setIdCategoria] = useState("");
  //tabs
  const [tabIndex, setTabIndex] = React.useState("one");

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setEvento({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEvento() {
      await gestaoApi
        .get("evento", {
          params: {
            id: idEvento,
            expand: "estabelecimento",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEvento({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEvento({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        })
        .catch((err) => {
          setEvento({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEvento();
  }, [idEvento]);

  const [eventoRegra, setEventoRegra] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [dias, setDias] = useState(0);
  const [diaEvento, setDiaEvento] = useState(1);
  const handleChangeDia = (dia) => {
    setDiaEvento(dia);
  };
  // const [posDifInd, setPosDifInd] = useState(1);
  // const [posDifEq, setPosDifEq] = useState(1);

  useEffect(() => {
    setEventoRegra({
      loading: true,
      error: false,
      payload: [],
    });
    if (idEvento) {
      async function getRegras() {
        await ConfedApi.get("evento-regra", {
          params: {
            id_evento: idEvento,
            expand: "modalidade,serie",
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventoRegra({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            let qtDias = response.data.items[0].num_linhas.split(",").length;
            setDias(qtDias);
            setDiaEvento(qtDias);
            // setPosDifInd(response.data.items[0].pos_dif_ind);
            // setPosDifEq(response.data.items[0].pos_dif_equipes);
            setEventoRegra({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
      }
      getRegras();
    }
  }, [idEvento]);

  const [partidas, setPartidas] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  useEffect(() => {
    setPartidas({
      loading: true,
      error: false,
      payload: [],
    });
    if (idEvento) {
      async function getPartidas() {
        await ConfedApi.get("evento-categoria-fase", {
          params: {
            id_evento: idEvento,
            id_categoria: idCategoria,
            expand: "eventoCategoriaPartidas,atleta1,atleta2",
            sort: "num_ordem",
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPartidas({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPartidas({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
      }
      getPartidas();
    }
  }, [idEvento, idCategoria]);

  const [activeCategoria, setActiveCategoria] = useState({
    baker: 0,
    dias: 0,
    pos_dif: 0,
  });
  useEffect(() => {
    setEventoCategoria({
      loading: true,
      error: false,
      payload: [],
    });
    if (idEvento) {
      async function getEventoCategoria() {
        await ConfedApi.get("evento-categoria", {
          params: {
            id_evento: idEvento,
            sort: "ordem",
            pageSize: 100,
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventoCategoria({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEventoCategoria({
              loading: false,
              error: false,
              payload: response.data.items,
            });
            // console.log(response.data.items);
            setActiveCategoria(response.data.items[0]);
            setIdCategoria(response.data.items[0].id);
          }
        });
      }
      getEventoCategoria();
    }
  }, [idEvento]);

  const handleChangeCategoria = (idCategoria) => {
    setIdCategoria(idCategoria);

    let categoria = _.filter(eventoCategoria.payload, function (o) {
      return Number(o.id) === Number(idCategoria);
    });
    setActiveCategoria(
      categoria[0]
        ? categoria[0]
        : {
          baker: 0,
          dias: 0,
          pos_dif: 0,
        }
    );
  };

  // const handleChangeCategoriaPartida = (idCategoria) => {
  // 	setIdCategoriaPartida(idCategoria)
  // }

  const [eventoRelatorio, setEventoRelatorio] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  useEffect(() => {
    setEventoRelatorio({
      loading: true,
      error: false,
      payload: [],
    });
    if (idEvento) {
      async function getRelatorios() {
        await portalApi
          .get("relatorio-evento", {
            params: {
              id_evento: idEvento,
              flag_del: 0,
              sort: "created_at",
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setEventoRelatorio({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setEventoRelatorio({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          });
      }
      getRelatorios();
    }
  }, [idEvento]);

  const [eventoAtleta, setEventoAtleta] = useState({
    loading: true,
    error: false,
    totalCount: 0,
    payload: [],
  });
  useEffect(() => {
    setEventoAtleta({
      loading: true,
      error: false,
      totalCount: 0,
      payload: [],
    });
    if (idEvento) {
      async function getEventoAtleta() {
        await ConfedApi.get("evento-atleta", {
          params: {
            id_evento: idEvento,
            expand: "eventoAtletaLinhas,totalAtletas",
            sort: "created_at",
            pageSize: 2000,
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventoAtleta({
              loading: false,
              error: true,
              totalCount: 0,
              payload: [],
            });
          } else {
            setEventoAtleta({
              loading: false,
              error: false,
              totalCount: response.data.items[0].totalAtletas,
              payload: response.data.items,
            });
          }
        });
      }
      getEventoAtleta();
    }
  }, [idEvento]);

  const [eventoAtletaTabela, setEventoAtletaTabela] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  useEffect(() => {
    setEventoAtletaTabela({
      loading: true,
      error: false,
      payload: [],
    });
    if (idEvento && idCategoria) {
      async function getEventoAtleta() {
        await ConfedApi.get("evento-atleta/tabela", {
          params: {
            id_evento: idEvento,
            dia: diaEvento,
            ctg: idCategoria,
          },
        })
          .then((response) => {
            if (response.data.length <= 0 || response.data.tabela.length <= 0) {
              setEventoAtletaTabela({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setEventoAtletaTabela({
                loading: false,
                error: false,
                payload: response.data,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            setEventoAtletaTabela({
              loading: false,
              error: true,
              payload: [],
            });
          });
      }
      getEventoAtleta();
    } else {
      setEventoAtletaTabela({
        loading: false,
        error: true,
        payload: [],
      });
    }
  }, [idEvento, idCategoria, diaEvento]);

  const calcNumLinhas = (regra) => {
    let linhas = regra.num_linhas.split(",");
    let teste = linhas.reduce((prev, curr) => Number(prev) + Number(curr), 0);
    return teste;
  };

  const formatFederacao = (evento) => {
    let federacao = "";
    if (evento.estabelecimento) {
      federacao = evento
        ? `${evento.estabelecimento.sigla} - ${evento.estabelecimento.descricao}`
        : "";
    }

    return federacao;
  };

  const mediaGeral = (eventoAtleta) => {
    let media = "";
    if (eventoAtleta.length) {
      let linhas = eventoAtleta.map((item) => item.eventoAtletaLinhas);

      let flatLinhas = _.flattenDepth(linhas, 1);

      let flatvalues = flatLinhas.map((item) => item.valor);

      let sumLinhas = _.reduce(
        flatvalues,
        function (sum, n) {
          return sum + n;
        },
        0
      );
      media = (sumLinhas / flatvalues.length).toFixed(2);
    }

    return media === "NaN" ? "" : media;
  };

  const breadcrumb = [
    { label: "eventos", url: "/eventos" },
    {
      label: evento.payload.descricao ? evento.payload.descricao : "",
      url: "/eventos",
    },
  ];

  const flagInscricao = (evento) => {
    var dataLimite = evento.payload.data_limit_inscricao;

    if (dataLimite != null && moment(dataLimite).diff(moment()) >= 0) {
      return (
        <a
          href={`${process.env.REACT_APP_SGE_URL}inscricao/escolha-atletas?id=${evento.payload.id}`}
          target={`_blank`}
        >
          <div className="accordion accordion-one right-circle mb-5">
            <h4>CLIQUE AQUI PARA SE INSCREVER</h4>
          </div>
        </a>
      );
    } else return "";
  };
  return (
    <>
      {evento.loading || evento.error ? (
        ""
      ) : (
        <Helmet>
          <title>
            {evento.payload?.descricao} - {process.env.REACT_APP_SITETITLE}
          </title>
        </Helmet>
      )}
      <Breadcrumbs paths={breadcrumb} />
      <main className="main boliche-evento">
        <div
          className="intro-section right-circle"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backs/evento.png)`,
          }}
        >
          <div className="intro-content">
            <div className="container">
              <h2>{evento.loading ? "" : evento.payload?.descricao}</h2>
              <h3>
                {evento.loading
                  ? ""
                  : formatDatePeriod(
                    evento.payload.data_inicio,
                    evento.payload.data_fim
                  )}
              </h3>
              <div className="d-flex flex-wrap">
                {evento.loading ? (
                  ""
                ) : (
                  <React.Fragment>
                    {evento.payload.local ? (
                      <span className="tag-evento">{evento.payload.local}</span>
                    ) : (
                      ""
                    )}
                    {evento.payload.escopo ? (
                      <Link
                        to={`/eventos?escopo=${evento.payload.escopo}`}
                        className="tag-evento"
                      >
                        {evento.payload.escopo}
                      </Link>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {flagInscricao(evento)}
          <div className="accordion accordion-two right-circle mb-5">
            {eventoRegra.loading || eventoRegra.error ? (
              ""
            ) : (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-7 order-1 order-md-1">
                  <div className="d-md-none">
                    <div className="accordion-title flex-wrap">
                      <div className="title-left mr-4 mb-3 mb-md-5">
                        <h2>Informações</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-7 order-3 order-md-2">
                  <div className="d-none d-md-block">
                    <div className="accordion-title flex-wrap">
                      <div className="title-left mr-4 mb-3 mb-md-5">
                        <h2>Informações</h2>
                      </div>
                    </div>
                  </div>
                  <h4>
                    <b>Organizador:</b> {formatFederacao(evento.payload)}
                  </h4>
                  <h4>
                    <b>Modalidade:</b>{" "}
                    {eventoRegra.payload[0].modalidade?.descricao}
                  </h4>
                  {eventoRegra.payload[0].oleo && (
                    <h4>
                      <b>Óleo:</b> {eventoRegra.payload[0].oleo}
                    </h4>
                  )}
                  <h4>
                    <b>Data:</b>{" "}
                    {evento.loading
                      ? ""
                      : formatDatePeriod(
                        evento.payload.data_inicio,
                        evento.payload.data_fim
                      )}
                  </h4>
                  <h4>
                    <b>Data Limite de Inscrição:</b>{" "}
                    {evento.loading
                      ? ""
                      : moment(evento.payload.data_limit_inscricao).format(
                        "DD/MM/YYYY"
                      )}
                  </h4>
                  <h4>
                    <b>Linhas:</b> {calcNumLinhas(eventoRegra.payload[0])}
                  </h4>
                  <h4>
                    <b>Atletas Inscritos no Torneio:</b>{" "}
                    {eventoAtleta.totalCount}
                  </h4>
                  <h4>
                    <b>Média geral do Torneio:</b>{" "}
                    {mediaGeral(eventoAtleta.payload)}
                  </h4>
                  <h4>
                    <b>Documentos:</b>{" "}
                    {eventoRelatorio.error && "Nenhum documento registrado."}
                  </h4>

                  <div className="btn-group-wrapper">
                    {eventoRelatorio.payload.map((item, key) => (
                      <a
                        key={key}
                        href={`${process.env.REACT_APP_SGE_URL}_uploads/relatorioEvento/${item.arquivo}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn-pill-evento mb-3 text-uppercase"
                      >
                        {item.descricao || item.nome_original}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-5 order-2 order-md-3">
                  {evento.payload.arte_evento && (
                    <div className="d-flex justify-content-lg-end my-2 my-lg-0">
                      <img
                        src={`${process.env.REACT_APP_SGE_URL}_uploads/relatorioEvento/${evento.payload.arte_evento}`}
                        alt={evento.payload.descricao}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-12 order-4">
                  <hr />
                  {eventoRegra.payload[0].aviso && (
                    <h4>
                      <b>Aviso:</b>{" "}
                      {ReactHtmlParser(eventoRegra.payload[0].aviso)}
                    </h4>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="container-fluid table-container-fluid"
          style={{ marginBottom: "4rem" }}
        >
          <div className="partidas-section p-4 p-md-0 mb-4">
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              className="evento-tabs"
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label="Resultados"
                wrapped
                {...a11yProps("one")}
              />
              <Tab
                value="two"
                label="Atletas Inscritos"
                {...a11yProps("two")}
              />
              <Tab
                value="three"
                label="Pontos no Ranking"
                {...a11yProps("three")}
              />
            </Tabs>
            <TabPanel value={tabIndex} index="one">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label pl-0 text-uppercase">
                    <b>Escolha a categoria:</b>
                  </label>
                  {eventoCategoria.loading || eventoCategoria.error ? (
                    ""
                  ) : (
                    <select
                      value={idCategoria}
                      onChange={(e) => handleChangeCategoria(e.target.value)}
                      className="form-select mb-4"
                    >
                      <option value="">Selecione</option>
                      {eventoCategoria.payload.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.divisao}ª DIVISÃO - {item?.descricao}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              {partidas.payload[0] && (
                <Partidas
                  partidas={partidas}
                  eventoCategoria={eventoCategoria}
                  idCategoria={idCategoria}
                  handleChangeCategoria={handleChangeCategoria}
                />
              )}

              <TabelaResultados
                categoria={activeCategoria}
                handleChangeDia={handleChangeDia}
                diaEvento={diaEvento}
                idCategoria={idCategoria}
                eventoAtletaTabela={eventoAtletaTabela}
                eventoCategoria={eventoCategoria}
                handleChangeCategoria={handleChangeCategoria}
                diasEvento={dias}
                eventoRegra={eventoRegra}
              />

              <MapaPontos idEvento={idEvento} />
            </TabPanel>
            <TabPanel value={tabIndex} index="two">
              <TabelaInscritos idEvento={idEvento} />
            </TabPanel>
            <TabPanel value={tabIndex} index="three">
              <div className="title-left mr-4 mb-3 mb-md-5">
                <h2>Masculino</h2>
              </div>
              <TabelaPontosRanking idEvento={idEvento} sexo={'M'} />
              <div className="title-left mr-4 mb-3 mb-md-5">
                <h2>Feminino</h2>
              </div>
              <TabelaPontosRanking idEvento={idEvento} sexo={'F'} />
            </TabPanel>
          </div>
          <Suspense>
            <NoticiasRelacionadas idEvento={idEvento} />
          </Suspense>
        </div>
      </main>
    </>
  );
}
